.homeNav {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  background-color: rgba(#ffffff, 0.9);

  &.scrolled {
    background-color: #ffffff;
  }

  > ul {
    list-style: none;
    padding-inline-start: 0;
    margin: 0;
    display: flex;
    border-bottom: 1px solid #dddddd;

    &.homeNavWrapper {
      padding: 22px 40px;

      > li {
        font-family: "Montserrat", sans-serif;
        font-size: 17px;
        line-height: 20px;
        font-weight: 400;
        letter-spacing: 0;
        color: #707070;

        > div {
          &:not(:last-child) {
            padding-right: 90px;
          }
        }

        .navLink {
          font-family: "Montserrat", sans-serif;
          font-size: 17px;
          line-height: 20px;
          font-weight: 400;
          letter-spacing: 0;
          color: #707070;
          text-decoration: none;
          transition: color 200ms;
          cursor: pointer;

          &:not(:last-child) {
            margin-right: 16px;
          }

          &:hover {
            color: #e8390e;
          }

          &.active {
            font-weight: 700;
            color: #e8390e;
          }
        }
      }
    }
  }
}