.fabWrapper {
  position: fixed;
  bottom: 30px;
  right: 30px;
  z-index: 400;
  display: flex;
  flex-direction: column;

  .supportIcon {
    box-sizing: border-box;
    width: 57px;
    height: 57px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    background: rgba($color: #000000, $alpha: 0.81) 0 0 no-repeat
    padding-box;
  }

  .topButton {
    box-sizing: border-box;
    width: 57px;
    height: 57px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    background-color: transparent;
    cursor: pointer;
  }

  > a,
  > div {
    &:not(:last-child) {
      margin-bottom: 10px;
    }
  }
}
