.swiper-button-prev:after,
.swiper-container-rtl .swiper-button-next:after {
    content: url("../../static/svg/icon-arrow-prevp.svg");
    width: 100%;
    height: 100%;
    font-size: inherit;
}

.swiper-button-next:after,
.swiper-container-rtl .swiper-button-prev:after {
    content: url("../../static/svg/icon-arrow-nextp.svg");
    width: 100%;
    height: 100%;
    font-size: inherit;
}

.swiper-button-prev,
.swiper-container-rtl .swiper-button-next {
    left: 20px;
    display: inline-block;
    width: auto;
    height: auto;
}

.swiper-button-next,
.swiper-container-rtl .swiper-button-prev {
    right: 20px;
    display: inline-block;
    width: auto;
    height: auto;
}

.swiper-slide.menu-slide {
    width: 18%;
}