.panelListWrapper {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    margin-left: -10px;
    margin-right: -10px;
    align-items: stretch;

    .panelWrapper {
        display: flex;
        flex-direction: column;
        flex-basis: 0;
        flex-grow: 1;
        max-width: 25%;
        width: 100%;
        position: relative;
        min-height: 1px;
        padding-left: 10px;
        padding-right: 10px;

        .panel {
            display: flex;
            flex-grow: 1;
            align-items: stretch;
            flex-direction: column;
            border-radius: 30px;
            border: 1px solid rgba($color: #ffffff, $alpha: 0.88);
            padding: 70px 30px;
            box-shadow: 0px 3px 16px #00000052;
            color: #ffffff;
            letter-spacing: 0;
            text-shadow: 0px 3px 6px #00000029;
            font-size: 18px;
            text-decoration: none;
            text-align: center;
            transition: all 200ms;
            align-items: center;

            .panelLogoWrapper {
                width: 58px;
                height: 58px;
                border-radius: 50%;
                background-color: rgba($color: #ffffff, $alpha: 0.31);
                margin-bottom: 40px;
                display: flex;
                align-items: center;
                justify-content: center;
                transition: background-color 200ms;
            }

            &:hover {
                background-color: #ffffff;
                box-shadow: 0px 3px 16px #00000052;
                color: #2f2f2f;
                text-shadow: 0px 3px 6px #00000029;

                .panelLogoWrapper {
                    background-color: #E8390E;
                }
            }
        }
    }
}