.lastSlideCircle {
  position: relative;
  box-sizing: border-box;
  min-width: 1px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  color: #ffffff;
  border-radius: 100%;
  border: 1px solid #ffffff;
  flex: 0 0 calc(50% - 30px);
  max-width: calc(50% - 30px);
  transition: all 300ms;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: -0.24px;
  font-family: 'Nanum Myeongjo', serif;
  font-weight: 400;
  margin: 10px 15px;

  &:before {
    content: "";
    float: left;
    padding-top: 100%;
  }

  &.active {
    background-color: #ffffff;
    color: #e8390e;
  }

  .lastSlideCircleElementWrapper {
    text-align: center;

    .lastSlideCircleText {
      padding-top: 16px;
    }
  }
}