/* Modal.css */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5); /* 반투명 배경 */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  position: relative;
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  max-width: 600px;
  max-height: 90%;
}

.modal-close {
  position: absolute;
  top: 5px;        /* 이미지와 겹치지 않도록 여백 조정 */
  right: 5px;      /* 이미지와 겹치지 않도록 여백 조정 */
  width: 30px;     /* 정사각형 크기 */
  height: 30px;    /* 정사각형 크기 */
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #ccc;  /* 네모 박스 테두리 */
  border-radius: 4px;      /* 모서리 살짝 둥글게 (선택 사항) */
  background: #fff;        /* 배경색 */
  font-size: 16px;         /* 글자 크기 */
  line-height: 1;          /* X자가 세로로 늘어나지 않도록 */
  cursor: pointer;
  z-index: 1;              /* 모달 내부 다른 요소보다 위에 표시 */
}


.modal-image {
  max-width: 100%;
  height: auto;
  cursor: pointer;
}
