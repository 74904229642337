.talentSectionOne {
  padding: 60px 0;
  position: relative;

  .talentTitleBlack {
    font-size: 24px;
    color: #333333;
    letter-spacing: 0;
    font-family: 'Nanum Myeongjo', serif;
    margin-bottom: 50px;
    text-align: center;
    font-weight: bolder;
  }

  .familyListWrapper {
    display: flex;
    margin: -22.5px 0;
    flex-wrap: wrap;
    width: 100%;
    box-sizing: border-box;
    padding: 0 20px;
    justify-content: center;
    align-items: center;

    .familyColumn {
      flex: 0 0 calc(50% - 45px);
      max-width: calc(50% - 45px);
      margin: 22.5px 22.5px;
      box-sizing: border-box;
      min-width: 1px;
      position: relative;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .familyColumnTitle {
        font-family: 'Nanum Myeongjo', serif;
        font-size: 14px;
        font-weight: bold;
        text-align: center;
        color: #2F2F2F;
        letter-spacing: 0;
        padding-top: 22px;
        padding-bottom: 10px;
        line-height: 16px;
      }

      .familyColumnDescription {
        color: #2F2F2F;
        letter-spacing: 0;
        font-size: 12px;
        font-family: 'Nanum Myeongjo', serif;
        font-weight: 400;
        line-height: 16px;
        white-space: nowrap;
        text-align: center;
      }
    }
  }
}

.talentSectionTwo {
  position: relative;
  width: 100%;
  height: 100%;

  .talentRightPeopleWrapper {
    padding: 60px 0;
    background-color: rgba($color: #000000, $alpha: 0.75);
    z-index: 2;
    position: relative;

    .talentRightPeopleTitle {
      color: #FFFFFF;
      font-family: 'Nanum Myeongjo', serif;
      font-size: 24px;
      text-align: center;
      letter-spacing: 0.1rem;
      font-weight: 500;
      margin-bottom: 20px;
    }

    .talentRightPeopleDescription {
      text-align: center;
      color: #FFFFFF;
      letter-spacing: 0;
      font-size: 12px;
      font-family: 'Nanum Myeongjo', serif;
      line-height: 18px;
      font-weight: 300;
      margin-bottom: 50px;
    }

    .talentRightPeopleListWrapper {
      position: relative;
      padding: 0 20px;

      .talentRightPeopleFlexWrapper {
        display: flex;
        align-items: center;
        justify-content: center;

        &:not(:last-child) {
          margin-bottom: 24px;
        }

        .talentRightPeopleCircle {
          position: relative;
          border: 1px solid #FFFFFF;
          border-radius: 100%;
          min-width: 105px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #FFFFFF;
          font-size: 14px;
          font-family: 'Nanum Myeongjo', serif;
          line-height: 18px;
          font-weight: bold;

          &:before {
            content: "";
            float: left;
            padding-top: 100%;
          }
        }

        .talentRightPeopleCircleDescription {
          color: #FFFFFF;
          font-size: 12px;
          line-height: 21px;
          letter-spacing: 0;
          font-family: 'Nanum Myeongjo', serif;
          font-weight: 400;
          padding-left: 20px;
        }
      }
    }
  }

  .talentRightPeopleBgImgWrapper {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
  }
}

.educationSection {
  padding: 60px 20px 26px;
  background: #F4F4F4 0 0 no-repeat padding-box;

  .educationTitle {
    color: #333333;
    font-size: 24px;
    font-family: 'Nanum Myeongjo', serif;
    letter-spacing: 0;
    font-weight: 400;
    margin-bottom: 20px;
    text-align: center;
  }

  .educationDescription {
    font-family: 'Nanum Myeongjo', serif;
    font-size: 12px;
    line-height: 16px;
    color: #2F2F2F;
    letter-spacing: 0;
    text-align: center;
    margin-bottom: 46px;
  }

  .educationBox {
    background-color: #FFFFFF;
    box-sizing: border-box;
    padding: 40px 30px;
    width: 100%;

    .educationBoxFlexWrapper {
      display: flex;
      align-items: center;

      &:not(:last-child) {
        margin-bottom: 40px;
      }

      .educationIconWrapper {
        padding-right: 40px;
      }

      .educationBoxListTitle {
        font-family: 'Nanum Myeongjo', serif;
        color: #2F2F2F;
        font-size: 16px;
        font-weight: bold;
        line-height: 18px;
        letter-spacing: 0;
      }

      .educationBoxListSubTitle {
        font-family: 'Nanum Myeongjo', serif;
        color: #2F2F2F;
        letter-spacing: 0;
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        margin-bottom: 13px;
      }

      .educationBoxListDescription {
        font-family: 'Nanum Myeongjo', serif;
        color: #2F2F2F;
        font-size: 12px;
        line-height: 24px;
        font-weight: 400;
        letter-spacing: 0;
      }
    }
  }
}

.welfareSection {
  padding: 66px 20px 26px;
  background: #F4F4F4 0 0 no-repeat padding-box;

  .welfareTitle {
    font-family: 'Nanum Myeongjo', serif;
    font-size: 24px;
    line-height: 16px;
    font-weight: 400;
    color: #2F2F2F;
    letter-spacing: 0;
    margin-bottom: 20px;
    text-align: center;
  }

  .welfareDescription {
    font-size: 12px;
    line-height: 16px;
    font-family: 'Nanum Myeongjo', serif;
    color: #2F2F2F;
    letter-spacing: 0;
    font-weight: 400;
    text-align: center;
    margin-bottom: 46px;
  }

  .welfareBox {
    background-color: #FFFFFF;
    padding: 30px 30px 38px;
    position: relative;
    box-sizing: border-box;
    width: 100%;

    &:not(:last-of-type) {
      margin-bottom: 20px;
    }

    .welfareRedDot {
      width: 7px;
      height: 7px;
      border-radius: 100%;
      background-color: #e8390e;
      text-align: center;
      margin: 0 auto 6px;
    }

    .welfareBoxTitle {
      color: #2F2F2F;
      font-size: 18px;
      font-family: 'Nanum Myeongjo', serif;
      text-align: center;
      letter-spacing: 0;
      margin-bottom: 35px;
      font-weight: 500;
    }

    .welfareBoxFlexWrapper {
      display: flex;
      position: relative;
      align-items: center;

      &:not(:last-child) {
        margin-bottom: 10px;
      }

      .welfareBoxRoundTitle {
        border: 1px solid #DDDDDD;
        border-radius: 18px;
        min-width: 78px;
        white-space: nowrap;
        padding: 8px 0;
        font-family: 'Nanum Myeongjo', serif;
        text-align: center;
        font-size: 12px;
      }

      .welfareBoxDescription {
        font-size: 12px;
        font-family: 'Nanum Myeongjo', serif;
        color: #2F2F2F;
        letter-spacing: 0;
        padding-left: 32px;
        word-break: keep-all;
        line-height: 18px;
        box-sizing: border-box;
      }
    }
  }
}