//어바웃 메인 페이지
.aboutMainSection {
  position: relative;
  top: 0;
  left: 0;

  .aboutMainBackground {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 349px;
  }

  .aboutMainWrapper {
    margin: 0 auto;
    padding: 160px 0 60px 0;

    .aboutMainTitle {
      margin-bottom: 21px;
      font-size: 24px;
      line-height: 36px;
      letter-spacing: 0;
      color: #2f2f2f;
      font-family: "Nanum Myeongjo", serif;
      font-weight: 400;
      text-align: center;

      > span {
        color: #e8390e;
        font-weight: bold;
      }
    }

    .aboutMainDesc {
      font-size: 12px;
      line-height: 18px;
      letter-spacing: 0;
      color: #2f2f2f;
      font-family: "Nanum Myeongjo", serif;
      font-weight: 400;
      text-align: center;

      > span {
        color: #e8390e;
        font-weight: bold;
      }
    }
  }
}

.aboutMenuSection {
  position: relative;

  .aboutMenuWrapper {
    display: flex;
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba($color: #000000, $alpha: 0.5);

    .aboutMenuTitle {
      margin: auto;
      font-size: 16px;
      line-height: 31px;
      letter-spacing: -0.32px;
      font-family: "Nanum Myeongjo", serif;
      font-weight: 400;
      text-align: center;
      color: #ffffff;
    }
  }

  .aboutMenuBackground {
    width: 100%;
  }
}

//어바웃 사업소개 페이지
.aboutBusinessSection {
  position: relative;
  width: 100%;

  .aboutBusinessWrapper {
    .aboutBusinessTitle {
      padding: 127px 23px 45px 23px;

      .aboutBusinessTitleText {
        font-size: 24px;
        line-height: 31px;
        color: #333333;
        font-family: "Nanum Myeongjo", serif;
        font-weight: 400;
      }
    }

    .aboutBusinessIntros {
      .aboutBusinessIntro {
        position: relative;

        .aboutBusinessIntroFrame {
          position: absolute;
          width: 100%;
          height: 100%;
          background-color: #000000;
          opacity: 0.5;
          transition: all 300ms;

          .aboutBusinessIntroWrapper {
            padding: 10px;
            height: calc(100% - 20px);

            .aboutBusinessIntroContent {
              display: flex;
              width: 100%;
              height: 100%;
              border: 1px solid #ffffff;

              .aboutBusinessIntroLogo {
                margin: auto;

                .aboutBusinessIntroLogoImg {
                  margin-bottom: 11.68px;
                }

                .aboutBusinessIntroLogoText {
                  font-size: 16px;
                  line-height: 31px;
                  letter-spacing: -0.32px;
                  font-family: "Nanum Myeongjo", serif;
                  font-weight: 400;
                  color: #ffffff;
                }
              }
            }

            .aboutBusinessIntroContentActive {
              width: 100%;
              height: 100%;
              border: 1px solid #ffffff;

              .aboutBusinessIntroContentWrapper {
                padding: 20px;

                .aboutBusinessIntroTitle {
                  font-size: 16px;
                  line-height: 20px;
                  letter-spacing: -0.32px;
                  font-family: "Nanum Myeongjo", serif;
                  font-weight: 400;
                  color: #ffffff;
                  text-align: right;
                }

                .aboutBusinessIntroDescBig {
                  font-size: 16px;
                  line-height: 16px;
                  letter-spacing: -0.32px;
                  font-family: "Nanum Myeongjo", serif;
                  font-weight: 400;
                  color: #ffffff;
                  text-align: left;
                }

                .aboutBusinessIntroDesc {
                  font-size: 11px;
                  line-height: 16px;
                  letter-spacing: -0.24px;
                  font-family: "Nanum Myeongjo", serif;
                  font-weight: 400;
                  color: #ffffff;
                  text-align: left;
                }
              }
            }
          }
        }

        .aboutBusinessIntroBackground {
          width: 100%;
          height: 160px;
          object-fit: cover;
        }
      }
    }
  }
}

//어바웃 연혁 페이지
.aboutHistorySection {
  position: relative;
  top: 0;
  left: 0;

  .aboutHistoryWrapper {
    padding: 120px 20px 0 20px;

    .aboutHistoryTitle {
      margin-bottom: 55px;
      font-size: 24px;
      line-height: 31px;
      font-family: "Nanum Myeongjo", serif;
      font-weight: 800;
      font-style: italic;
      text-align: center;
      color: #2f2f2f;
    }

    .aboutHistoryTimeLine {
      position: relative;
      margin: 0 auto;
      width: 100%;

      .aboutHistoryList {
        margin: 0 0 50px 0;
        padding: 0;
        list-style-type: none;
      }

      .aboutHistoryListItem {
        margin: 0 0 43px 0;
        padding: 0;
        list-style-type: none;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
      }

      .aboutHistoryDesc {
        width: 50%;

        .aboutHistoryDescImg {
          margin-bottom: 18px;
          width: 100%;
          height: auto;
          object-fit: cover;
        }

        .aboutHistoryDescTitle {
          margin-bottom: 10px;
          font-size: 12px;
          line-height: 18px;
          color: #2f2f2f;
          font-family: "Nanum Myeongjo", serif;
          font-weight: 400;
        }

        .aboutHistoryDescYear {
          margin-bottom: 5px;
          font-size: 12px;
          line-height: 13px;
          color: #e8390e;
          font-family: "Nanum Myeongjo", serif;
          font-weight: 400;
        }

        .aboutHistoryDescSub {
          font-size: 12px;
          line-height: 18px;
          color: #707070;
          font-family: "Nanum Myeongjo", serif;
          font-weight: 400;
        }
      }

      .aboutHistoryPoint {
        min-width: 20px;
        height: 20px;
        background-color: #ffffff;
        border-radius: 100%;
        z-index: 2;
        transition: all 1s;
        border: 7px solid #dddddd;
        position: relative;
        left: 1px;
        align-self: flex-start;
        margin-left: 20px;
        margin-right: 20px;
      }

      .aboutHistoryDate {
        width: 50%;
        font-size: 16px;
        line-height: 21px;
        color: #2f2f2f;
        font-family: "Nanum Myeongjo", serif;
        font-style: italic;
        font-weight: 830;
        text-align: left;
      }

      .aboutHistoryDateRight {
        width: 50%;
        font-size: 16px;
        line-height: 21px;
        color: #2f2f2f;
        font-family: "Nanum Myeongjo", serif;
        font-style: italic;
        font-weight: 830;
        text-align: right;
      }
    }

    .aboutHistoryTimeLine::before {
      content: "";
      position: absolute;
      height: calc(100% - 70px);
      width: 1px;
      left: 50%;
      background-color: #dddddd;
    }
  }
}

//어바웃 사업소개 페이지
.aboutFinancialStatements {
  position: relative;
  width: 100%;

  .aboutFinancialStatementsWrapper {
    .aboutFinancialStatementsTitle {
      padding: 127px 23px 45px 23px;

      .aboutFinancialStatementsTitleText {
        font-size: 24px;
        line-height: 31px;
        color: #333333;
        font-family: "Nanum Myeongjo", serif;
        font-weight: 400;
      }
    }
  }

  .aboutFinancialStatementsSection {
    position: relative;
    margin-bottom: 10px;
    top: 0;
    left: 0;

    .contactFaqWrapper {
      padding: 20px;
    }

    .cardHeaderWrapper {
      display: flex;
      align-items: center;

      .cardHeaderNum {
        margin-right: 12px;
        font-size: 12px;
        line-height: 23px;
        text-align: left;
        color: #2f2f2f;
      }

      .cardHeaderCategory {
        margin-left: auto;
        font-size: 12px;
        line-height: 23px;
        text-align: left;
        color: #2f2f2f;
        white-space: nowrap;
      }

      .cardHeaderMain {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        padding-right: 16px;

        .cardHeaderTitle {
          margin-bottom: 2px;
          font-size: 12px;
          line-height: 17px;
          text-align: left;
          color: #2f2f2f;
          word-break: keep-all;
        }
      }

      .cardHeaderSideWrapper {
        .cardHeaderSideDate {
          font-size: 10px;
          line-height: 14px;
          color: #707070;
          opacity: 0.5;
        }
      }
    }

    .cardDescWrapper{
      .cardDescText{
        display: flex;
        justify-content: center;
      }
    }
  }
}
