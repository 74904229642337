.storySection {
  padding: 48px 20px 62px;
  position: relative;
  background-color: #151515;

  .storyBrandTitle {
    font-family: 'Nanum Myeongjo', serif;
    font-size: 28px;
    letter-spacing: 0.84px;
    color: #C70017;
    font-weight: 500;
  }

  .storyBrandSince {
    font-size: 16px;
    color: #FFFFFF;
    letter-spacing: 0.48px;
    font-family: 'Nanum Myeongjo', serif;
    font-weight: 400;
    font-style: italic;
    margin-bottom: 28px;
  }

  .storyMainImageWrapper {
    position: relative;
    height: 240px;
    margin-bottom: 12px;
  }

  .storyDivider {
    width: 2px;
    background-color: #C70017;
    height: 88px;
    margin: 26px 0;

    &.storyMainDivider {
      position: absolute;
      left: 20px;
      bottom: -80px;
      margin: 12px 0;
    }
  }

  .storyMainDescription {
    color: #FFFFFF;
    padding: 70px 20px 84px;
    font-size: 14px;
    font-family: 'Nanum Myeongjo', serif;
    letter-spacing: 0;
    line-height: 22px;
    word-break: keep-all;
  }

  .storyBrandDescription {
    color: #FFFFFF;
    text-align: center;
    padding-top: 40px;
    font-family: 'Nanum Myeongjo', serif;
    font-size: 14px;
    line-height: 23px;
    letter-spacing: 0;

    > b {
      font-size: 24px;
      font-weight: 400;
    }
  }

  .historyTimelineWrapper {
    margin-bottom: 48px;

    .historyTimelineTitle {
      font-family: "Nanum Myeongjo", serif;
      color: #ffffff;
      text-align: center;
      font-size: 14px;
      letter-spacing: 0;
      margin-bottom: 27px;
    }

    .timeline {
      position: relative;
      margin: 0 auto;
      padding-bottom: 27px;

      &::before {
        content: "";
        position: absolute;
        height: 100%;
        width: 2px;
        left: 50%;
        background-color: #c70017;
      }

      ul {
        li {
          flex-direction: row;
          //margin-bottom: 50px;
          list-style-type: none;
          display: flex;
          align-items: flex-start;

          .content {
            width: 50%;

            .timelineContentWrapper {
              color: #ffffff;
              font-size: 12px;
              font-family: "Nanum Myeongjo", serif;
              letter-spacing: 0;
              line-height: 18px;
              display: flex;
              word-break: keep-all;

              &.contentLeft {
                justify-content: flex-end;
                text-align: right;

                .timelineContentDescriptionWrapper {
                  //margin-right: 30px;
                }
              }

              &.contentRight {
                justify-content: flex-start;
                text-align: left;

                .timelineContentDescriptionWrapper {
                  //margin-left: 30px;
                }
              }

              .timelineContentNumber {
                font-size: 21px;
                font-weight: 300;
                color: #c70017;
                font-style: italic;
                line-height: 1.2;
                margin-bottom: 16px;
              }
            }
          }

          .date {
            width: 50%;
            font-weight: normal;
          }
        }
      }
    }

    .point {
      min-width: 1px;
      height: 1px;
      //background-color: #ffffff;
      border-radius: 100%;
      z-index: 2;
      //border: 7px solid #dddddd;
      position: relative;
      left: 1px;
      align-self: flex-start;
      margin-left: 12px;
      margin-right: 12px;
    }
  }

  .storyCheeseKatsuImageWrapper {
    position: relative;
    margin-bottom: 28px;
  }

  .storyRecipeMainDescription {
    font-size: 14px;
    color: #FFFFFF;
    font-family: 'Nanum Myeongjo', serif;
    letter-spacing: 0;
    margin-bottom: 60px;
    line-height: 22px;
  }

  .storyRecipeBoxWrapper {
    &:not(:last-child) {
      margin-bottom: 60px;
    }
  }
}

.storyRecipeBoxWrapper {
  position: relative;
  background-color: #151515;

  .storyRecipeBoxImageWrapper {
    height: 255px;
    margin-bottom: 20px;
  }

  .storyRecipeTitle {
    color: #FFFFFF;
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 20px;
    font-family: 'Nanum Myeongjo', serif;
    line-height: 18px;
  }

  .storyRecipeDescription {
    font-size: 14px;
    line-height: 22px;
    color: #FFFFFF;
    font-weight: 400;
    letter-spacing: 0;
    font-family: 'Nanum Myeongjo', serif;
  }
}

.storeSection {
  position: relative;

  .storePanelWrapper {
    position: relative;

    .storePanelImageWrapper {
      height: 128px;

      > img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .storePanelDescriptionWrapper {
      position: relative;
      padding: 26px 30px;

      .storePanelTitle {
        font-family: 'Nanum Myeongjo', serif;
        font-size: 16px;
        color: #333333;
        letter-spacing: 0;
        margin-bottom: 15px;
        font-weight: bold;
      }

      .storePanelDescription {
        font-size: 12px;
        line-height: 22px;
        color: #333333;
        letter-spacing: 0;
        font-weight: 400;
        font-family: 'Nanum Myeongjo', serif;
      }

      .storePanelAddress {
        margin-top: 10px;
        font-size: 8px;
        line-height: 22px;
        color: #333333;
        letter-spacing: 0;
        font-weight: 400;
        font-family: 'Nanum Myeongjo', serif;
      }
    }
  }

  .mapWrapper {
    padding-top: 60px;
    position: relative;

    .mapTitle {
      margin: 20px 0;
      font-size: 16px;
      font-family: 'Nanum Myeongjo', serif;
      font-weight: bold;
      color: #2F2F2F;
      text-align: center;
      letter-spacing: 0;
    }

    .mapDescription {
      color: #2F2F2F;
      font-size: 12px;
      line-height: 17px;
      letter-spacing: 0;
      font-family: 'Nanum Myeongjo', serif;
      text-align: center;
      margin-bottom: 26px;
    }

    .mapStoreLinkWrapper {
      padding: 0 70px;
      margin-bottom: 40px;

      .mapStoreLink {
        display: inline-block;
        width: 100%;
        padding: 21px 0;
        text-align: center;
        background-color: rgba(232, 57, 14, 0.9);
        color: rgba(255, 255, 255, 0.8);
        font-family: 'Nanum Myeongjo', serif;
        font-size: 14px;
        letter-spacing: 0;
        font-weight: 400;
        border-radius: 8px;
        box-shadow: 0 3px 0 rgba(#000, 0.32);
      }
    }
  }

  .storeMapImageWrapper {
    position: relative;

    .storeMapMarkerPosition {
      position: absolute;
      display: inline-block;
    }
  }
}

.sabotenMenuSection {
  position: relative;
  padding: 40px 0;
  background-color: #151515;
  background-image: url(../../../../static/images/img-p-saboten-background-1.png);
  background-size: cover;

  .menuTitle {
    font-family: 'Nanum Myeongjo', serif;
    color: #FFFFFF;
    font-size: 24px;
    letter-spacing: 0;
    font-weight: 500;
    font-style: italic;
    margin-bottom: 40px;
    text-align: center;
  }

  .menuTextListWrapper {
    padding: 0 100px;
    margin: 0 auto;
    position: relative;

    .menuText {
      font-size: 18px;
      font-family: 'Nanum Myeongjo', serif;
      letter-spacing: 2px;
      color: #FFFFFF;
      margin-bottom: 27px;
      font-weight: 600;

      &.menuActive {
        &:before {
          content: "";
          position: absolute;
          left: 0;
          width: 90px;
          border-top: 1px solid #FFFFFF;
          margin-top: 10px;
        }

        &:after {
          content: "";
          position: absolute;
          width: 4px;
          height: 4px;
          background-color: #FFFFFF;
          border-radius: 100%;
        }
      }

      &:nth-child(even) {
        padding-left: 20px;
        padding-right: 20px;

        &.menuActive {
          &:before {
            content: "";
            position: absolute;
            left: 0;
            width: 110px;
            border-top: 1px solid #FFFFFF;
            margin-top: 10px;
          }
        }
      }
    }
  }

  .menuImageWrapper {
    height: 165px;
    position: relative;
  }
}

.menuNavigatorWrapper {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin: 0;
  padding: 20px 0 0;

  .menuNavigator {
    flex: 0 0 calc(20% - 2px);
    width: 100%;
    max-width: calc(20% - 2px);
    text-align: center;
    border: 1px solid #EEEEEE;
    padding: 12px 0;

    &.menuNavigatorActive {
      border-color: #C70017;

      > a {
        color: #C70017;
      }
    }

    > a {
      color: #707070;
      font-size: 12px;
      line-height: 17px;
      letter-spacing: 0;
      font-family: 'Noto Sans KR', sans-serif;
      display: inline-block;
      white-space: nowrap;
    }
  }
}

.menuDetailSection {
  position: relative;
  padding-top: 50px;
  background-color: #FFFFFF;
  background-size: cover;


  .menuDetailListWrapper {
    position: relative;

    &:before {
      content: "";
      position: absolute;
      width: 10px;
      left: 90px;
      background-color: #C50021;
      height: 100%;
      z-index: 1;
    }

    .menuDetailWrapper {
      position: relative;
      padding-bottom: 56px;

      .menuDetailImageWrapper {
        position: relative;
        padding-left: 30px;

        > img {
          position: relative;
          z-index: 2;
          width: 200px;
          height: auto;
          object-fit: cover;
        }
      }

      .menuDetailDescriptionWrapper {
        padding-top: 10px;
        padding-left: 150px;

        .menuDetailEnTitle {
          font-size: 12px;
          color: #707070;
          letter-spacing: 0;
          font-family: 'Nanum Myeongjo', serif;
          padding-bottom: 10px;
          font-style: italic;
          word-break: keep-all;
        }

        .menuDetailTitle {
          padding-bottom: 13px;
          font-family: 'Nanum Myeongjo', serif;
          font-size: 12px;
          color: #2f2f2f;
          letter-spacing: 0;
        }

        .menuDetailPrice {
          font-size: 16px;
          font-family: 'Nanum Myeongjo', serif;
          font-style: italic;
          letter-spacing: 0;
          color: #2F2F2F;
        }
      }
    }
  }
}