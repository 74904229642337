.subBannerParagraph {
    font-family: "Nanum Myeongjo", serif;
    color: #ffffff;
    text-align: center;
    font-size: 20px;
    letter-spacing: 0;
    padding-top: 16px;
    margin: 0;
}

.subBannerHoverList {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-between;
    margin: 0 auto;

    @media screen and (min-width: 768px) {
        max-width: 720px;
    }

    @media screen and (min-width: 992px) {
        max-width: 960px;
    }

    @media screen and (min-width: 1200px) {
        max-width: 1140px;
    }

    @media screen and (min-width: 1680px) {
        max-width: 1500px;
    }

    .subBannerHoverElement {
        flex-grow: 1;
        flex-basis: 0;
        position: relative;
        cursor: pointer;

        .subBannerHoverCard {
            position: absolute;
            bottom: 0;
            width: 100%;

            .cardImageWrapper {
                width: 100%;
                height: 100%;
                position: relative;

                .cardBackgroundWrapper {
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    border-radius: 20px;
                    background-color: rgba($color: #000000, $alpha: 0.6);

                    .cardTextWrapper {
                        width: 100%;
                        height: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        flex-direction: column;

                        .cardTextTitle {
                            font-size: 36px;
                            font-family: "Nanum Myeongjo", serif;
                            color: #ffffff;
                            text-shadow: 0 3px 6px #00000029;
                            letter-spacing: 0;
                            font-weight: 400;
                            text-align: center;
                        }

                        .cardTextSubTitle {
                            font-family: "Nanum Myeongjo", serif;
                            color: #ffffff;
                            font-weight: 400;
                            letter-spacing: 0;
                            font-size: 20px;
                            margin-bottom: 1.25rem;
                            text-align: center;

                            @media screen and (max-height: 768px) {
                                font-size: 16px;
                            }
                        }

                        .cardTextParagraph {
                            font-family: "Nanum Myeongjo", serif;
                            color: #ffffff;
                            font-weight: 400;
                            letter-spacing: 0;
                            font-size: 14px;
                            line-height: 28px;
                            text-align: center;
                            padding: 0 0.5rem;
                            word-break: keep-all;

                            @media screen and (max-height: 768px) {
                                font-size: 14px;
                            }
                        }
                    }
                }
            }
        }
    }
}

.subBannerDot {
    width: 9px;
    height: 9px;
    border-radius: 50%;
    background-color: rgba($color: #ffffff, $alpha: 0.5);
    margin-left: calc(100px - 2.5vw);
    margin-right: calc(100px - 2.5vw);
    align-self: center;
}
