.productSectionOne {
  padding: 30px 40px 40px;
  background-color: #151515;
  position: relative;

  .productCircleListWrapper {
    display: flex;
    flex-wrap: wrap;
    box-sizing: border-box;
    width: 100%;
    justify-content: center;
    position: relative;

    .productCircle {
      flex: 0 0 calc(50% - 20px);
      max-width: calc(50% - 20px);
      position: relative;
      border-radius: 100%;
      border: 1px solid #FFFFFF;
      margin: 13.5px 10px;
      width: 100%;
      box-sizing: border-box;

      &.lastCircle {
        border: 1px solid transparent;
        background-color: #e8390e;
      }

      &:before {
        content: "";
        float: left;
        padding-top: 100%;
      }

      .productCircleContentWrapper {
        float: left;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 24px 0 28px;
        box-sizing: border-box;
        position: relative;

        &.first {
          &:before {
            position: absolute;
            width: 5px;
            height: 5px;
            content: "";
            border-radius: 100%;
            top: -3px;
            background-color: #FFFFFF;
          }
        }

        &.second {
          &:before {
            position: absolute;
            width: 5px;
            height: 5px;
            content: "";
            border-radius: 100%;
            right: -3px;
            background-color: #FFFFFF;
          }
        }

        &.third {
          &:before {
            position: absolute;
            width: 5px;
            height: 5px;
            content: "";
            border-radius: 100%;
            bottom: -3px;
            background-color: #FFFFFF;
          }
        }

        &.fourth {
          &:before {
            position: absolute;
            width: 5px;
            height: 5px;
            content: "";
            border-radius: 100%;
            left: -3px;
            background-color: #FFFFFF;
          }
        }

        .productCircleImageWrapper {
          padding-bottom: 14px;
        }

        .productCircleContentDescription {
          font-family: 'Nanum Myeongjo', serif;
          font-size: 12px;
          color: #FFFFFF;
          letter-spacing: 0;
          line-height: 16px;
          text-align: center;
          font-weight: 400;
        }
      }
    }
  }
}

.productSectionTwo {
  padding: 40px 0 60px;
  position: relative;
  background-color: #FFFFFF;

  .productHomeMadeKatsuDescription {
    font-size: 12px;
    line-height: 24px;
    letter-spacing: 0;
    font-family: 'Nanum Myeongjo', serif;
    color: #2f2f2f;
    text-align: center;
    padding-bottom: 30px;
    font-weight: 400;
  }
}

.productHomemadeLinkWrapper {
  padding: 0 70px;

  .productHomemadeLink {
    width: 100%;
    display: inline-block;
    padding: 24px 0 21px;
    text-align: center;
    background: rgba($color: #E8390E, $alpha: 0.9) 0 0 no-repeat padding-box;
    box-shadow: 0 3px 0 rgba(0,0,0,0.16);
    border-radius: 4px;
    font-size: 14px;
    color: rgba($color: #FFFFFF, $alpha: 0.8);
    letter-spacing: -0.28px;
    font-family: 'Nanum Myeongjo', serif;
  }
}

.productSectionThree {
  position: relative;
  background-color: #FFFFFF;
  padding: 40px 0 80px;

  .productHomeMadeSauceDescription {
    font-size: 12px;
    line-height: 24px;
    font-family: 'Nanum Myeongjo', serif;
    color: #2F2F2F;
    letter-spacing: 0;
    text-align: center;
    word-break: keep-all;
    font-weight: 400;
    padding-bottom: 40px;
  }

  .productHomeMadeStoreLinkWrapper {
    position: relative;
    padding-top: 60px;
  }

  .storeLinkWrapper {
    padding: 0 37px;

    &:not(:last-child) {
      margin-bottom: 13px;
    }

    .naverStoreLinkWrapper {
      padding: 24px 32px;
      border: 2px solid #459D25;
      border-radius: 8px;
      display: flex;
      align-items: center;
    }

    .kakaoStoreLinkWrapper {
      padding: 19px 32px;
      border: 2px solid #FAE300;
      border-radius: 8px;
      display: flex;
      align-items: center;
    }

    .storeLink {
      margin-left: auto;
      display: inline-block;
      font-family: 'Noto Sans KR', sans-serif;
      color: #2F2F2F;
      font-weight: bold;
      letter-spacing: -0.3px;
      font-size: 15px;
      line-height: 19px;
    }
  }
}

.productKatsuSection {
  padding-top: 37px;
  padding-bottom: 28px;
  background-color: #151515;
}

.navigatorWrapper {
  display: flex;
  justify-content: center;
  padding-bottom: 20px;
  list-style: none;
  padding-left: 0;
  padding-right: 0;
  margin: 0;

  > li {
    flex-basis: 0;
    flex-grow: 1;
    min-width: 1px;

    .navigator {
      padding: 13px 12px 15px;
      text-align: center;
      font-size: 12px;
      line-height: 17px;
      letter-spacing: 0;
      text-decoration: none;
      display: inline-block;
      width: 100%;
      box-sizing: border-box;

      &.darkThemeNavigator {
        border-bottom: 1px solid rgba(#FFFFFF, 0.2);
        color: rgba(#FFFFFF, 0.53);

        &.darkThemeNavActive {
          border: 1px solid #E8390E;
          opacity: 1;
          color: #EA4C25;
        }
      }

      &.lightThemeNavigator {
        border-bottom: 1px solid #dddddd;
        color: #707070;

        &.lightThemeNavActive {
          border: 1px solid #e8390e;
          color: #e8390e;
        }
      }
    }
  }
}

.productSauceSection {
  padding: 40px 0 0;

  .productSauceTitle {
    text-align: center;
    margin: 0 0 38px;
    padding-top: 20px;
    font-family: 'Nanum Myeongjo', serif;
    font-size: 20px;
    line-height: 31px;
    letter-spacing: 0;
    color: #333333;
    font-weight: 400;
  }

  .mainSauceListWrapper {
    border-bottom: 1px solid #dddddd;
    padding: 32px 0 28px;

    .mainSauceTitle {
      font-family: 'Nanum Myeongjo', serif;
      font-weight: bold;
      font-size: 16px;
      letter-spacing: 0.48px;
      color: #2f2f2f;
      margin-bottom: 9px;
      text-align: center;
      padding-top: 18px;
    }

    .mainSauceDescription {
      font-family: 'Nanum Myeongjo', serif;
      font-size: 12px;
      font-weight: 400;
      letter-spacing: 0.38px;
      color: #2F2F2F;
      text-align: center;
    }
  }

  .newSauceListWrapper {
    background-color: #151515;
    padding: 40px 20px;

    .newSauceTitle {
      text-align: center;
      color: #FFFFFF;
      font-size: 20px;
      line-height: 22px;
      margin-top: 0;
      /*margin-bottom: 30px;*/
      letter-spacing: 0;
      margin-bottom: 0;
      font-family: 'Nanum Myeongjo', serif;
      font-weight: 400;
    }

    .newSauceDescription {
      font-family: 'Nanum Myeongjo', serif;
      font-weight: 300;
      color: #FFFFFF;
      font-size: 10px;
      text-align: center;
      margin-top: 4px;
      margin-bottom: 30px;
    }

    .newSauceButtonList {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;

      &:not(:last-of-type) {
        margin-bottom: 17px;
      }

      .newSauceButton {
        display: flex;
        border: 1px solid #FFFFFF;
        border-radius: 30px;
        align-items: center;
        padding: 10px 18px;
        margin-right: 12px;
        background-color: transparent;
        color: #FFFFFF;
        font-family: 'Noto Sans KR', sans-serif;
        font-size: 12px;
        letter-spacing: 0;
        opacity: 0.5;
        white-space: nowrap;
        transition: opacity 300ms;

        > img {
          padding-right: 8px;
        }

        &.newSauceButtonActive {
          opacity: 1;
        }

        &:focus {
          outline: none;
        }
      }
    }

    .newSauceImageWrapper {
      padding-top: 40px;
    }
  }

  .curryImageWrapper {
    position: relative;

    .curryImageDescriptionWrapper {
      position: absolute;
      width: 100%;
      height: 100%;
      padding-left: 20px;
      padding-top: 38px;
      padding-bottom: 29px;
      box-sizing: border-box;

      .curryImageSubDescription {
        color: #FFFFFF;
        font-size: 12px;
        text-decoration: none;
        letter-spacing: 0;
        font-family: 'Nanum Myeongjo', serif;
        font-weight: 400;
        margin-bottom: 4px;
      }

      .curryImageDescription {
        color: #FFFFFF;
        font-size: 16px;
        text-decoration: none;
        letter-spacing: 0;
        font-family: 'Nanum Myeongjo', serif;
        font-weight: bold;
      }
    }
  }
}

.productSauceDetailSection {
  padding-top: 37px;
  padding-bottom: 57px;
  position: relative;

  .sauceDetailImageWrapper {
    margin-bottom: 30px;
  }

  .sauceDetailFirstDescriptionsWrapper {
    padding: 0 20px;

    .sauceDetailFirstTitle {
      font-family: 'Nanum Myeongjo', serif;
      font-size: 16px;
      font-weight: bold;
      line-height: 29px;
      color: #2F2F2F;
      letter-spacing: 0;
    }

    .sauceDetailPrice {
      font-family: 'Nanum Myeongjo', serif;
      font-size: 14px;
      color: #2F2F2F;
      letter-spacing: 0;
      margin-bottom: 30px;
    }

    .sauceDetailTextSubTitle {
      font-weight: bold;
      font-family: 'Nanum Myeongjo', serif;
      font-size: 16px;
      line-height: 29px;
      letter-spacing: 0;
      color: #2F2F2F;
    }

    .sauceDetailTextDescription {
      font-size: 12px;
      font-weight: 400;
      line-height: 18px;
      font-family: 'Nanum Myeongjo', serif;
      color: #2F2F2F;
      letter-spacing: 0;
      margin-bottom: 30px;
      word-break: keep-all;
    }

    .storeButtonWrapper {
      padding: 0 16px;
      margin-bottom: 40px;

      .naverStoreButton {
        width: 100%;
        box-sizing: border-box;
        margin-bottom: 13px;
        padding: 20px 28px;
        border: 2px solid #459D25;
        border-radius: 8px;
        display: flex;
        align-items: center;
        margin-left: auto;
        font-family: 'Noto Sans KR', sans-serif;
        color: #2F2F2F;
        font-weight: bold;
        letter-spacing: -0.3px;
        font-size: 15px;
        line-height: 19px;

        > img {
          margin-right: auto;
        }
      }

      .kakaoStoreButton {
        width: 100%;
        box-sizing: border-box;
        margin-bottom: 13px;
        padding: 14px 28px;
        border: 2px solid #FAE300;
        border-radius: 8px;
        display: flex;
        align-items: center;
        margin-left: auto;
        font-family: 'Noto Sans KR', sans-serif;
        color: #2F2F2F;
        font-weight: bold;
        letter-spacing: -0.3px;
        font-size: 15px;
        line-height: 19px;

        > img {
          margin-right: auto;
        }
      }
    }
  }
}

.sauceDetailRecipeSection {
  padding: 10px 10px 10px 18px;
  background-color: #151515;
  position: relative;
}

.sauceNavigatorWrapper {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  box-sizing: border-box;
  padding: 0 0.25px;

  &:last-child {
    margin-bottom: 20px;
  }

  > li {
    flex-basis: 0;
    flex-grow: 1;
    border: 1px solid #DDDDDD;
    box-sizing: border-box;
    text-align: center;
    padding: 16px 18px;
    font-size: 12px;
    white-space: nowrap;
    font-weight: 300;

    &.sauceNavigatorActive {
      border: 1px solid #E8390E;
    }
  }

  .sauceNavigator {
    display: inline-block;
    color: #707070;

    &.sauceNavigatorActive {
      color: #E8390E;
    }
  }
}