.normalLogo {
    fill: #ffffff;
}

.hoveredLogo {
    //fill: #e2e2e2;
    fill: #e8390e;
}

.content {
    float: left;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    &:hover {
        >a {
            color: #2f2f2f;
        }
    }

    >a {
        text-decoration: none;
        color: #ffffff;
    }
}