@font-face {
    font-family: "RyuGHbatang";
    src: local("RyuGHbatangOTF00"),
        url("./static/fonts/RyuGHbatangOTF00.woff2") format("woff2"),
        url("./static/fonts/RyuGHbatangOTF00.woff") format("woff");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

/* nanum-myeongjo-regular - latin_korean */
@font-face {
    font-family: "Nanum Myeongjo";
    font-style: normal;
    font-weight: 400;
    src: url("./static/fonts/nanum-myeongjo-v15-latin_korean-regular.eot"); /* IE9 Compat Modes */
    src: local("NanumMyeongjo"),
        url("./static/fonts/nanum-myeongjo-v15-latin_korean-regular.eot?#iefix")
            format("embedded-opentype"),
        /* IE6-IE8 */
            url("./static/fonts/nanum-myeongjo-v15-latin_korean-regular.woff2")
            format("woff2"),
        /* Super Modern Browsers */
            url("./static/fonts/nanum-myeongjo-v15-latin_korean-regular.woff")
            format("woff"),
        /* Modern Browsers */
            url("./static/fonts/nanum-myeongjo-v15-latin_korean-regular.ttf")
            format("truetype"),
        /* Safari, Android, iOS */
            url("./static/fonts/nanum-myeongjo-v15-latin_korean-regular.svg#NanumMyeongjo")
            format("svg"); /* Legacy iOS */
}
/* nanum-myeongjo-700 - latin_korean */
@font-face {
    font-family: "Nanum Myeongjo";
    font-style: normal;
    font-weight: 700;
    src: url("./static/fonts/nanum-myeongjo-v15-latin_korean-700.eot"); /* IE9 Compat Modes */
    src: local("NanumMyeongjo Bold"), local("NanumMyeongjoBold"),
        url("./static/fonts/nanum-myeongjo-v15-latin_korean-700.eot?#iefix")
            format("embedded-opentype"),
        /* IE6-IE8 */
            url("./static/fonts/nanum-myeongjo-v15-latin_korean-700.woff2")
            format("woff2"),
        /* Super Modern Browsers */
            url("./static/fonts/nanum-myeongjo-v15-latin_korean-700.woff")
            format("woff"),
        /* Modern Browsers */
            url("./static/fonts/nanum-myeongjo-v15-latin_korean-700.ttf")
            format("truetype"),
        /* Safari, Android, iOS */
            url("./static/fonts/nanum-myeongjo-v15-latin_korean-700.svg#NanumMyeongjo")
            format("svg"); /* Legacy iOS */
}
/* nanum-myeongjo-800 - latin_korean */
@font-face {
    font-family: "Nanum Myeongjo";
    font-style: normal;
    font-weight: 800;
    src: url("./static/fonts/nanum-myeongjo-v15-latin_korean-800.eot"); /* IE9 Compat Modes */
    src: local("NanumMyeongjo ExtraBold"), local("NanumMyeongjoExtraBold"),
        url("./static/fonts/nanum-myeongjo-v15-latin_korean-800.eot?#iefix")
            format("embedded-opentype"),
        /* IE6-IE8 */
            url("./static/fonts/nanum-myeongjo-v15-latin_korean-800.woff2")
            format("woff2"),
        /* Super Modern Browsers */
            url("./static/fonts/nanum-myeongjo-v15-latin_korean-800.woff")
            format("woff"),
        /* Modern Browsers */
            url("./static/fonts/nanum-myeongjo-v15-latin_korean-800.ttf")
            format("truetype"),
        /* Safari, Android, iOS */
            url("./static/fonts/nanum-myeongjo-v15-latin_korean-800.svg#NanumMyeongjo")
            format("svg"); /* Legacy iOS */
}

/* noto-sans-kr-regular - latin_korean */
@font-face {
    font-family: "Noto Sans KR";
    font-style: normal;
    font-weight: 400;
    src: url("./static/fonts/noto-sans-kr-v12-latin_korean-regular.eot"); /* IE9 Compat Modes */
    src: local("Noto Sans KR Regular"), local("NotoSansKR-Regular"),
        url("./static/fonts/noto-sans-kr-v12-latin_korean-regular.eot?#iefix")
            format("embedded-opentype"),
        /* IE6-IE8 */
            url("./static/fonts/noto-sans-kr-v12-latin_korean-regular.woff2")
            format("woff2"),
        /* Super Modern Browsers */
            url("./static/fonts/noto-sans-kr-v12-latin_korean-regular.woff")
            format("woff"),
        /* Safari, Android, iOS */
            url("./static/fonts/noto-sans-kr-v12-latin_korean-regular.svg#NotoSansKR")
            format("svg"); /* Legacy iOS */
}
/* noto-sans-kr-700 - latin_korean */
@font-face {
    font-family: "Noto Sans KR";
    font-style: normal;
    font-weight: 700;
    src: url("./static/fonts/noto-sans-kr-v12-latin_korean-700.eot"); /* IE9 Compat Modes */
    src: local("Noto Sans KR Bold"), local("NotoSansKR-Bold"),
        url("./static/fonts/noto-sans-kr-v12-latin_korean-700.eot?#iefix")
            format("embedded-opentype"),
        /* IE6-IE8 */
            url("./static/fonts/noto-sans-kr-v12-latin_korean-700.woff2")
            format("woff2"),
        /* Super Modern Browsers */
            url("./static/fonts/noto-sans-kr-v12-latin_korean-700.woff")
            format("woff"),
        /* Safari, Android, iOS */
            url("./static/fonts/noto-sans-kr-v12-latin_korean-700.svg#NotoSansKR")
            format("svg"); /* Legacy iOS */
}
