.supportDetailBackgroundWrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba($color: #000000, $alpha: 0.67);

  .supportDetailLayoutWrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    text-align: center;

    .supportDetailTitle {
      margin-top: auto;
      margin-bottom: 80px;
      --animation-duration: 1.5s;
    }

    .supportDetailMenuList {
      display: flex;
      justify-content: center;
      list-style: none;
      padding: 0 0 12px 0;
      margin: 0;

      > .supportDetailMenu {
        &:not(:last-child) {
          margin-right: 30px;
        }

        .supportDetailMenuLink {
          display: inline-block;
          padding: 16px 34px;
          color: rgba($color: #ffffff, $alpha: 0.5);
          text-decoration: none;
          border-bottom: 3px solid transparent;

          &:hover {
            color: rgba($color: #ffffff, $alpha: 0.8);
            border-bottom: 3px solid rgba($color: #ffffff, $alpha: 0.8);
          }

          &.active {
            color: #ffffff;
            border-bottom: 3px solid #ffffff;

            &:hover {
              color: #ffffff;
              border-bottom: 3px solid #ffffff;
            }
          }
        }
      }
    }
  }
}

.giveawaySection {
  padding-top: 80px;
  padding-bottom: 120px;
  position: relative;
  background-color: #f4f4f4;

  .giveawayPanel {
    width: 800px;
    margin: 0 auto;
    background-color: #2f2f2f;
    padding: 65px 45px 30px;

    .giveawayTitle {
      text-align: center;
      color: #ededed;
      font-family: "Nanum Myeongjo", serif;
      font-weight: bold;
      font-size: 28px;
      line-height: 45px;
      letter-spacing: -0.56px;
      margin-bottom: 60px;
    }

    .giveawayLogoLine {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 64px;

      .giveawayLine {
        border-top: 1px solid rgba($color: #fff, $alpha: 0.5);
        flex-grow: 1;
        flex-basis: 0;
      }

      .giveawayLogoWrapper {
        padding: 0 30px;
      }
    }

    .giveawayImageWrapper {
      text-align: center;
      margin-bottom: 54px;
    }

    .giveawayListWrapper {
      margin-bottom: 40px;

      .giveawayList {
        color: #ffffff;
        padding-left: 40px;
        margin: 0;

        > li {
          font-size: 16px;
          line-height: 34px;
          letter-spacing: -0.34px;
          font-family: "Noto Sans KR", sans-serif;
          font-weight: 400;
          font-size: 16px;

          &:not(:last-child) {
            margin-bottom: 8px;
          }
        }
      }
    }

    .giveawayPanelFooterLine {
      width: 100%;
      border-top: 1px solid rgba($color: #fff, $alpha: 0.5);
      margin-bottom: 20px;
    }

    .giveawayContact {
      font-size: 16px;
      font-family: "Noto Sans KR", sans-serif;
      letter-spacing: -0.32px;
      color: #ffffff;
      line-height: 18px;
      text-align: center;
    }
  }
}

.tempDetailContent {
  padding-top: 80px;
  padding-bottom: 70px;
  position: relative;
  background-color: #f4f4f4;

  .tempKakaotalkPanel {
    width: 800px;
    margin: 0 auto;
    background-color: #ffe833;
    padding-top: 50px;
    position: relative;

    .tempKakaotalkPanelTitle {
      text-align: center;
      font-size: 33px;
      letter-spacing: -0.66px;
      padding-top: 12px;

      b {
        font-size: 45px;
      }
    }

    .tempKakaotalkPanelSubTitle {
      text-align: center;
      font-size: 24px;
      letter-spacing: -0.48px;
      color: #2f2f2f;
      padding-bottom: 76px;
    }

    .tempKakaotalkPanelFlexWrapper {
      display: flex;
      padding: 0 16px;

      .tempKakaotalkPanelBenefitWrapper {
        padding-left: 40px;

        .tempKakaotalkPanelBenefitTextWrapper {
          display: flex;
          margin-bottom: 11px;
          align-items: center;

          .tempKakaoPanelBenefitCircle {
            padding: 6px 12px;
            border-radius: 100%;
            background-color: #391b1b;
            font-size: 16px;
            letter-spacing: -0.32px;
            color: #ffffff;
            width: 33px;
            height: 33px;
            text-align: center;
            box-sizing: border-box;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 13px;
            font-weight: bold;
          }

          .tempKakaoPanelBenefitText {
            font-size: 18px;
            letter-spacing: -0.36px;
            color: #2f2f2f;
          }
        }
      }
    }

    .tempKakaoPanelFooter {
      background-color: #f4d32f;
      padding: 19px 30px;
      display: flex;
      justify-content: space-evenly;

      .tempKakaoPanelFooterBox {
        border: 2px solid #dbbb19;
        border-radius: 8px;
        padding: 14px 20px;
        display: flex;
        background-color: #ffffff;
        align-items: center;
        box-sizing: border-box;
        height: 100%;

        .tempKakaoPanelFooterBoxText {
          padding-left: 10px;
          font-size: 15px;
          color: #2f2f2f;
          letter-spacing: -0.34px;
          text-decoration: none;
        }

        &:not(:last-child) {
          margin-right: 18px;
        }
      }
    }
  }

  .tempPanel {
    width: 800px;
    margin: 0 auto;
    background-color: #ffffff;
    padding: 65px 0;
    text-align: center;

    .tempPanelTitle {
      font-size: 28px;
      font-weight: bold;
      letter-spacing: -0.56px;
      color: #2f2f2f;
      font-family: "Nanum Myeongjo", serif;
      padding-bottom: 34px;
    }

    .tempPanelDescription {
      font-size: 18px;
      letter-spacing: -0.36px;
      color: #2f2f2f;
      padding-top: 34px;
    }
  }

  .tempDetailButtonWrapper {
    position: absolute;
    right: 48px;
    bottom: 52px;

    .tempDetailButton {
      width: 70px;
      height: 70px;
      border-radius: 50%;
      background-color: #e8390e;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #ffffff;
      font-size: 13px;
      font-family: "Montserrat", sans-serif;
      border: none;
      padding: 0;
      cursor: pointer;

      &:focus {
        outline: none;
        border: none;
        box-shadow: none;
      }
    }
  }
}

.supportListWrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba($color: #000000, $alpha: 0.67);

  .supportTextWrapper {
    padding-top: 190px;
    text-align: center;
    padding-bottom: 150px;
  }
}

 .giveawayPanelMembership {
	width : 860px;
	margin: 0 auto;
}
