.swiper-container-vertical > .swiper-wrapper {
    flex-direction: column !important;
}

.swiper-container {
    height: 100vh;
}

.kalisco-button {
    padding: 28px 54px;
    background-color: rgba($color: #e8390e, $alpha: 0.9);
    box-shadow: 0px 3px 0px #00000029;
    border-radius: 8px;
    font-family: "Nanum Myeongjo", serif;
    font-size: 18px;
    line-height: 26px;
    color: rgba($color: #ffffff, $alpha: 0.8);
    letter-spacing: -0.36px;
    text-decoration: none;
}

.swiper-pagination-bullets {
    right: 43px !important;
    top: 50%;
    transform: translate3d(0px, -50%, 0);

    &.swiper-pagination-bullets-dynamic {
        top: 50%;
        transform: translateY(-50%);
        width: 8px;

        .swiper-pagination-bullet {
            display: inline-block;
            transition: 200ms transform, 200ms top;
        }
    }
}

.swiper-pagination-bullet {
    margin: 6px 0;
    display: block;
    //background-color: transparent;
    background-color: transparent;
    border: 1px solid #ffffff;

    //&.swiper-pagination-bullet
    &.swiper-pagination-bullet-active {
        background-color: rgba(#ffffff, 0.9);
    }
}

.main-bg-wrapper {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(#000000, 0.65);

    > .first-main-banner-title-wrapper {
        width: 100%;
        height: 100%;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        > .first-main-banner-title {
            font-family: "Nanum Myeongjo", serif;
            color: #ffffff;
            font-size: 55px;
            letter-spacing: 0;
            font-weight: 400;
            margin-bottom: 40px;
        }

        > .first-main-banner-sub-title {
            font-family: "Nanum Myeongjo", serif;
            color: #ffffff;
            font-weight: 400;
            margin: 0;
            font-size: 28px;
            letter-spacing: 0;
        }
    }

    .kalisco-membership-title-wrapper {
        margin-bottom: 48px;
    }

    .kalisco-membership-paragraph {
        font-family: "Nanum Myeongjo", serif;
        color: #ffffff;
        font-weight: 400;
        margin: 0;
        font-size: 24px;
        line-height: 32px;
        letter-spacing: 0;
        text-align: center;
    }

    .vertical-hr {
        width: 1px;
        max-height: 100%;
        border: none;
        border-left: 2px solid #fff;

        &.membership-hr {
            max-height: 213px;

            @media screen and (max-height: 768px) {
                max-height: 100px;
            }

            flex-grow: 1;
            margin: 45px 0;
            opacity: 0.5;
        }

        &.sub-banner-hr {
            max-height: 200px;

            @media screen and (max-height: 768px) {
                max-height: 100px;
            }

            flex-grow: 1;
            margin: 32px 0;
            opacity: 0.5;
        }
    }

    .product-intro-title {
        font-family: "Nanum Myeongjo", serif;
        margin-bottom: 48px;
        letter-spacing: 1.5px;
        color: #ffffff;
        font-size: 50px;
        line-height: 64px;
        text-shadow: 0px 3px 6px #00000052;
        text-align: center;
    }

    .product-intro-paragraph {
        font-family: "Nanum Myeongjo", serif;
        text-align: center;
        color: #ffffff;
        letter-spacing: 0;
        font-size: 24px;
        line-height: 32px;
    }
}

.main-business-intro-img-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;

    > div {
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%;
        width: 100%;
        //position: relative;
        min-height: 1px;
    }
}

.brand-list-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .brand-circle-wrapper {
        position: relative;
        width: 200px;
        height: 200px;
        cursor: pointer;
        background-color: rgba($color: #ffffff, $alpha: 0.31);
        border-radius: 50%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        transition: background-color 250ms, box-shadow 250ms;
        text-decoration: none;

        &:not(:last-child) {
            margin-right: 50px;
        }

        &:hover {
            //background: #517E71 0 0 no-repeat padding-box;
            box-shadow: 5px 5px 32px #00000052;

            &.saboten {
                background-color: rgba($color: #e8390e, $alpha: 0.5);
            }

            &.hibarin {
                background-color: rgba($color: #517e71, $alpha: 0.8);
            }

            &.tacobell {
                background-color: rgba($color: #480f72, $alpha: 0.5);
            }

            &.centralkc {
                background-color: rgba($color: #2f2f2f, $alpha: 0.5);
            }

            &.banjoo {
                background-color: rgba($color: #bc711f, $alpha: 0.5);
            }

            &.reperk {
                background-color: rgba($color: #c1852b, $alpha: 0.5);
            }
        }

        .brand-logo-wrapper {
            padding: 16px 0;
            display: inline-block;

            > img {
                width: 100%;
            }
        }

        .brand-title {
            font-family: "Nanum Myeongjo", serif;
            letter-spacing: -0.34px;
            color: #ffffff;
            text-align: center;
            font-size: 17px;
            line-height: 31px;
            font-weight: 400;
        }
    }
}

.main-footer-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    position: relative;

    .main-footer-info-wrapper {
        background-color: #2f2f2f;
        width: 100%;
        height: 100%;
        flex-grow: 1;
        display: flex;
        align-items: center;

        .main-footer-circle {
            position: relative;
            border-radius: 50%;
            border: 1px solid rgba($color: #ffffff, $alpha: 0.77);
            letter-spacing: -0.36px;
            color: #ffffff;
            font-family: "Nanum Myeongjo", serif;
            text-align: center;
            font-size: 18px;
            transition: background-color 250ms, box-shadow 250ms;
            cursor: pointer;

            &::before {
                content: "";
                float: left;
                padding-top: 100%;
            }

            &:hover {
                border: 1px solid #ffffff;
                box-shadow: 0px 3px 6px #00000029;
                color: #2f2f2f;
                background-color: rgba($color: #ffffff, $alpha: 0.77);
            }

            .content {
                float: left;
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
            }

            &:not(:last-child) {
                margin-right: 44px;
            }
        }
    }
}

.viewport-wrapper {
    width: 100%;
    height: 100vh;

    & > .main-bg-wrapper {
        height: 100vh;

        & > .brand-list-wrapper {
            flex-wrap: wrap;
        }
    }
}

.scroll-guide {
    position: fixed;
    bottom: 30px;
    z-index: 300;
    white-space: nowrap;
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;

    .scroll-guide-text {
        font-size: 12px;
        color: rgba(#fff, 0.8);
        letter-spacing: -0.24px;
        padding-bottom: 9px;
    }
}

@media only screen and (max-width: 1200px) {
    .main-footer-circle {
        position: relative;
        border-radius: 100%;
        border: 1px solid rgba($color: #ffffff, $alpha: 0.77);
        letter-spacing: -0.36px;
        color: #ffffff;
        font-family: "Nanum Myeongjo", serif;
        text-align: center;
        font-size: 18px;
        transition: background-color 250ms, box-shadow 250ms;
        cursor: pointer;
        flex: 0 0 50% !important;
        max-width: 50% !important;

        &::before {
            content: "";
            float: left;
            padding-top: 100%;
        }

        &:hover {
            border: 1px solid #ffffff;
            box-shadow: 0 3px 6px #00000029;
            color: #2f2f2f;
            background-color: rgba($color: #ffffff, $alpha: 0.77);
        }

        .content {
            float: left;
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
        }
    }
}
