.storyFlexWrapper {
  align-self: center;
  text-align: left;
  display: flex;
  justify-content: center;
  flex-direction: column;

  .storySpaceWrapper {
    padding-left: 170px;

    .storyTitle {
      font-size: 20px;
      letter-spacing: 0;
      color: #707070;
      font-family: "Nanum Myeongjo", serif;
      line-height: 29px;
    }

    .storyVerticalHr {
      width: 1px;
      height: 200px;
      border-right: 2px solid #517e71;
      margin: 20px 0;
    }

    .storyDescription {
      letter-spacing: 0;
      color: #707070;
      font-family: "Nanum Myeongjo", serif;
      font-size: 18px;
      line-height: 29px;
    }
  }
}

.menuFirstSection {
  padding: 80px 0;
  position: relative;
  background-color: #ffffff;

  .menuTitleTextWrapper {
    text-align: center;
    padding-bottom: 80px;
  }
}

.menuSwiper {
  height: auto;
}

.menuListSection {
  padding-top: 100px;
  background-image: url(../../static/images/img-hibarin-special-back.png);
  background-position: center center;
  background-size: cover;
  background-repeat: repeat repeat;

  .MenuListFlexWrapper {
    display: flex;
    margin-bottom: 58px;
  }

  .menuBorderLine {
    align-self: center;
    min-height: 1px;

    &.katsuBorderLine,
    &.noodleBorderLine {
      width: 80%;
      max-width: 540px;
      margin-right: 38px;
    }

    &.donburiBorderLine,
    &.sideBorderLine {
      width: 85%;
      max-width: 617px;
      margin-right: 40px;
    }
  }

  .menuListTitle {
    font-size: 40px;
    line-height: 64px;
    color: #ffffff;
    letter-spacing: 1.2px;
    font-family: "Montserrat", sans-serif;
    display: flex;
    align-items: center;
    position: relative;
    font-weight: 600;

    &.menuListTitleActive {
      &::after {
        content: " ";
        width: 8px;
        height: 8px;
        border-radius: 100%;
        margin-left: 5px;
        margin-bottom: 16px;
      }
    }
  }

  &.katsuSection {
    background-color: #ffe4c7;

    .katsuBorderLine {
      border-top: 1px solid #bc711f;
    }

    .menuListTitleActive {
      color: #ad5c03;

      &::after {
        background-color: #bc711f;
      }
    }
  }

  &.donburiSection {
    background-color: #ded8ce;

    .donburiBorderLine {
      border-top: 1px solid #bc9b69;
    }

    .menuListTitleActive {
      color: #bc9b69;

      &::after {
        background-color: #bc9b69;
      }
    }
  }

  &.noodleSection {
    background-color: #d5e3df;

    .noodleBorderLine {
      border-top: 1px solid #517e71;
    }

    .menuListTitleActive {
      color: #517e71;

      &::after {
        background-color: #517e71;
      }
    }
  }

  &.sideSection {
    background-color: #ead3cd;

    .sideBorderLine {
      border-top: 1px solid #d86245;
    }

    .menuListTitleActive {
      color: #e8390e;

      &::after {
        background-color: #e8390e;
      }
    }
  }

  .menuListLinkWrapper {
    text-decoration: none;
    display: inline-block;
  }

  .menuListTitleWrapper {
    padding-bottom: 76px;
    text-align: center;
  }

  .menuListGridWrapper {
    align-items: center;
    background-color: #ffffff;

    img {
      transition: opacity 250ms;
      opacity: 0.34;

      &.active {
        opacity: 1;
      }
    }
  }
}

.menuDetailSection {
  padding-top: 50px;
  padding-bottom: 83px;
  position: relative;
  background-color: #ffffff;

  .categoryButtonListWrapper {
    margin-bottom: 50px;
    display: flex;
    justify-content: center;
  }

  .menuDetailPanelWrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
  }

  .fixedMenuListFlexWrapper {
    min-width: 1800px;
    margin: 0 auto;

    .menuListFlexColumnWrapper {
      padding-left: 76px;
      padding-right: 76px;
      display: flex;
      flex-direction: column;

      .menuListFlexColumn {
        display: flex;

        &:first-child {
          margin-top: 50px;
        }

        &:not(:last-child) {
          margin-bottom: 50px;
        }

        .menuListNumber {
          font-size: 16px;
          line-height: 19px;
          font-family: "Montserrat", sans-serif;
          color: #707070;
          letter-spacing: 0;
        }

        .menuListRecipeImageWrapper {
          flex: 0 0 40%;
          max-width: 40%;
          max-height: 177px;
          text-align: center;
          padding-left: 22px;
          padding-right: 40px;

          img {
            max-height: 100%;
          }
        }

        .menuListEnTitle {
          font-size: 20px;
          font-family: "Nanum Myeongjo", serif;
          letter-spacing: 0;
          color: #707070;
          font-style: italic;
          padding-bottom: 11px;
        }

        .menuListTitle {
          font-size: 18px;
          line-height: 29px;
          font-family: "Nanum Myeongjo", serif;
          color: #2f2f2f;
          letter-spacing: 0;
          padding-bottom: 20px;
          font-weight: 600;
        }

        .menuListDescription {
          font-size: 16px;
          line-height: 19px;
          font-family: "Montserrat", sans-serif;
          color: #707070;
          letter-spacing: 0;
          padding-bottom: 20px;
          white-space: pre-line;
        }

        .menuListPrice {
          font-size: 24px;
          line-height: 31px;
          color: #2f2f2f;
          letter-spacing: 0;
          font-style: italic;
          font-family: "Nanum Myeongjo", serif;
        }
      }

      .sideMenuWrapper {
        display: flex;
        border-top: 1px solid #2f2f2f;
        border-bottom: 1px solid #2f2f2f;
        padding: 20.5px;
        align-items: center;

        .sideMenuEnTitle {
          font-style: italic;
          font-size: 18px;
          font-family: "Nanum Myeongjo", serif;
          letter-spacing: 0;
          color: #707070;
          padding-bottom: 5px;
          white-space: nowrap;
        }

        .sideMenuTitle {
          font-size: 14px;
          color: #2f2f2f;
          letter-spacing: 0;
          font-family: "Nanum Myeongjo", serif;
          white-space: nowrap;
        }

        .sideMenuPrice {
          font-size: 24px;
          color: #2f2f2f;
          letter-spacing: 0;
          font-family: "Nanum Myeongjo", serif;
          font-style: italic;
        }

        > div {
          &:not(:last-child) {
            padding-right: 45px;
          }

          &:first-child {
            padding-right: 20px;
          }
        }
      }

      .drinkMenuWrapper {
        border-top: 2px solid #2f2f2f;
        border-bottom: 2px solid #2f2f2f;
        padding-top: 20.5px;
        padding-bottom: 20.5px;
        margin-top: 50px;

        .drinkMenuFlexWrapper {
          display: flex;
          align-items: center;

          &:not(:last-child) {
            margin-bottom: 50px;
          }

          .drinkMenuEnTitle {
            font-size: 24px;
            font-family: "Montserrat", sans-serif;
            letter-spacing: 0;
            margin-bottom: 0.325rem;
            font-weight: 400;
          }

          .drinkMenuTitle {
            font-size: 18px;
            font-family: "Montserrat", "Noto Sans KR", sans-serif;
            letter-spacing: 0;
            color: #2f2f2f;
            font-weight: 600;
          }

          .drinkMenuPrice {
            font-size: 18px;
            line-height: 29px;
            font-family: "Montserrat", sans-serif;
            font-weight: 600;
            margin-left: auto;
          }
        }
      }
    }
  }
}

.storeSection {
  padding: 80px 0;
  background-color: #ffffff;
  position: relative;

  .titleTextWrapper {
    text-align: center;
    padding-bottom: 80px;
  }

  .storeImageWrapper {
    margin-bottom: 80px;
  }

  .storeTextWrapper {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;

    .storeTextTitle {
      font-size: 28px;
      font-family: "Nanum Myeongjo", serif;
      letter-spacing: 0;
      color: #ffffff;
      padding-bottom: 30px;
    }

    .storeTextDescription {
      font-size: 16px;
      font-family: "Nanum Myeongjo", serif;
      letter-spacing: 0;
      color: #ffffff;
      line-height: 22px;
    }
    .storeTextAddress {
      margin-top: 15px;
      font-size: 11px;
      font-family: "Nanum Myeongjo", serif;
      letter-spacing: 0;
      color: #ffffff;
      line-height: 22px;
    }
  }

  .storeMapWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .storeMarkerWrapper {
      padding-bottom: 30px;
    }

    .storeMapTitle {
      font-family: "Nanum Myeongjo", serif;
      font-size: 28px;
      letter-spacing: 0;
      color: #2f2f2f;
      padding-bottom: 40px;
      font-weight: bold;
    }

    .storeMapDescription {
      font-family: "Nanum Myeongjo", serif;
      font-size: 20px;
      color: #2f2f2f;
      letter-spacing: 0;
      padding-bottom: 40px;
    }
  }

  .storeMapImageWrapper {
    position: relative;

    .storeMapMarkerPosition {
      position: absolute;
      display: inline-block;
    }
  }
}

.kaliscoButton {
  padding: 21px 54px 18px;
  background-color: rgba($color: #e8390e, $alpha: 0.9);
  box-shadow: 0px 3px 0px #00000029;
  border-radius: 8px;
  font-family: "Nanum Myeongjo", serif;
  font-size: 18px;
  line-height: 26px;
  color: rgba($color: #ffffff, $alpha: 0.8);
  letter-spacing: -0.36px;
  text-decoration: none;
  display: inline-block;
  min-width: 240px;
  box-sizing: border-box;
  text-align: center;
}

.storeInfotblWrapper {
  padding: 30px 0;
}
.storeInfotblWrapper table {
  width: 100%;
}
.storeInfotblWrapper table.saboten thead tr {
  background: #c70017;
  border-top: 1px solid #000;
  border-bottom: 1px solid #000;
}
.storeInfotblWrapper table.hibarin thead tr {
  background: #517e71;
  border-top: 1px solid #999;
  border-bottom: 1px solid #999;
}
.storeInfotblWrapper table thead tr th {
  padding: 7px 10px;
  color: #fff;
  font-weight: bold;
  text-align: center;
  font-size: 18px;
}
.storeInfotblWrapper table tbody tr {
  border-bottom: 1px solid #999;
}
@media (hover: hover) {
  .storeInfotblWrapper table tbody tr:hover {
    background: rgba(111, 111, 111, 0.2);
  }
}
.storeInfotblWrapper table tbody tr td {
  padding: 5px 10px;
}
.storeInfotblWrapper table tbody tr td:first-of-type {
  text-align: center;
}
.storeInfotblWrapper table tbody tr td:nth-of-type(2) {
  padding-left: 20px;
}

