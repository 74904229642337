.contactSection {
  position: relative;
  background-color: #ffffff;
  padding-top: 60px;
  padding-bottom: 80px;

  .contactTitle {
    font-family: "Montserrat", sans-serif;
    font-size: 19px;
    line-height: 23px;
    color: #2f2f2f;
    letter-spacing: 0;
    font-weight: 700;
    margin-bottom: 20px;
  }

  .contactFormPanel {
    border-radius: 10px;
    border: 1px solid #dddddd;
    padding: 40px 31px;

    .commonFormLabel {
      display: inline-block;
      padding-bottom: 4px;
      font-size: 16px;
      letter-spacing: 0;
      color: #5f6374;
      font-weight: 600;
    }

    .commonFormInput {
      padding: 8px 11px;
      border: 1px solid #e8eaef;
      background-color: #ffffff;
      min-height: 34px;
      box-sizing: border-box;
      color: #272d48;
      display: inline-block;
      font-size: 13px;
      line-height: normal;

      &:focus {
        outline: none;
      }

      &.writerFormInput {
        width: 360px;
      }

      &.telFormInput {
        width: 260px;
        height: 100%;

        &:placeholder-shown {
          color: rgba($color: #272d48, $alpha: 0.7);
        }
      }
    }

    .commonSelectInput {
      background-color: #ffffff;
      border: 1px solid #e8eaef;
      padding: 8px 16px;
      min-height: 34px;
      color: #272d48;
      font-size: 13px;
      box-sizing: border-box;
      display: inline-block;
      background-clip: padding-box;
      line-height: normal;

      &.telSelectInput {
        min-width: 170px;
        margin-right: 10px;
      }

      &.categorySelectInput {
        min-width: 170px;
        margin-right: 75px;
      }

      &.categorySubInput {
        min-width: 290px;
      }
    }

    .titleInput {
      padding: 8px 11px;
      border-top: 1px solid transparent;
      border-left: 1px solid transparent;
      border-right: 1px solid transparent;
      border-bottom: 1px solid #dddddd;
      background-color: #ffffff;
      min-height: 34px;
      box-sizing: border-box;
      color: #272d48;
      display: inline-block;
      font-size: 13px;
      line-height: normal;
      width: 100%;

      &:focus {
        outline: none;
      }

      &:placeholder-shown {
        color: rgba($color: #5f6374, $alpha: 0.5);
      }
    }

    .descriptionPanel {
      padding: 16px;
      border: 1px solid #e8eaef;

      .description {
        width: 100%;
        border: none;
        background-color: transparent;
        min-height: 130px;
        resize: none;
        font-size: 14px;
        padding: 0;

        &:focus {
          outline: none;
        }
      }

      .countText {
        text-align: right;
        letter-spacing: 0;
        color: rgba(#5f6374, 0.5);
        font-size: 13px;
      }
    }

    .termTable {
      //border: 1px solid #dddddd;
      width: 100%;
      border-spacing: 0;
      border-collapse: collapse;

      td {
        padding: 8px;
        border: 1px solid #e8eaef;
        color: #272d48;
        font-size: 13px;
        line-height: normal;

        &.tdTitle {
          width: 20%;
          font-size: 14px;
          text-align: center;
          font-weight: bolder;
        }
      }
    }

    .checkboxInlineGroup {
      margin: 8px 0;
      text-align: center;
    }

    .checkboxInline {
      display: inline-flex;
      align-items: center;
      padding-left: 0;
      position: relative;

      &:not(:last-child) {
        margin-right: 24px;
      }

      .checkboxInlineRadio {
        position: static;
        margin-top: 0;
        margin-right: .45rem;
        box-sizing: border-box;
        padding: 0;
      }

      label {
        font-size: 12px;
        cursor: pointer;
      }
    }

    .termDescription {
      font-size: 14px;
      text-align: center;
      line-height: 1.25;
    }

    .fileLabel {
      padding: 8px 32px 8px 16px;
      border: 1px solid #e8eaef;
      background-color: #ffffff;
      display: inline-block;
      min-width: 354px;
      margin-right: 10px;
      color: #272d48;
      letter-spacing: 0;
      font-size: 13px;
      position: relative;
      box-sizing: border-box;

      &::after {
        content: url(../../static/svg/paperclip.svg);
        position: absolute;
        right: 15px;
      }
    }

    .fileButton {
      border: 1px solid #e8390e;
      padding: 8px 27px;
      min-width: 100px;
      text-align: center;
      display: inline-block;
      font-size: 14px;
      font-family: "Montserrat", sans-serif;
      letter-spacing: 0;
      color: #e8390e;
      background-color: #ffffff;
      cursor: pointer;

      .fileButtonLabel {
        cursor: pointer;
      }
    }

    .formWrapper {
      padding-bottom: 30px;

      &:last-of-type {
        padding-bottom: 60px;
      }
    }

    .formSubitButtonWrapper {
      display: flex;
      justify-content: center;

      > div {
        &:not(:last-child) {
          margin-right: 20px;
        }

        .resetButton {
          border-radius: 22px;
          background-color: #dddddd;
          padding: 12px 34px;
          min-width: 100px;
          color: #ffffff;
          font-size: 18px;
          box-shadow: none;
          outline: none;
          border: none;
          cursor: pointer;
        }

        .submitButton {
          border-radius: 22px;
          background-color: #e8390e;
          padding: 12px 34px;
          min-width: 100px;
          color: #ffffff;
          font-size: 18px;
          box-shadow: none;
          outline: none;
          border: none;
          cursor: pointer;
        }
      }
    }
  }
}

.noticeSection {
  background-color: #ffffff;
  padding-top: 78px;
  padding-bottom: 100px;

  .searchFormWrapper {
    display: flex;
    justify-content: space-between;
    margin-bottom: 23px;

    .searchFormTitle {
      font-size: 24px;
      font-family: "Montserrat", sans-serif;
      line-height: 29px;
      color: #2f2f2f;
      letter-spacing: 0;
      font-weight: bold;
    }

    .searchFormInputWrapper {
      display: flex;

      .searchFormInput {
        background-color: #ffffff;
        border: 1px solid #e8eaef;
        padding: 8px;
      }

      .searchFormButtonWrapper {
        margin-left: 10px;

        .searchFormButton {
          border-radius: 4px;
          background-color: #e8390e;
          padding: 8px 22px;
          color: #ffffff;
          letter-spacing: 0;
          font-size: 14px;
          line-height: 19px;
          border: 0;
          cursor: pointer;

          &:focus {
            outline: none;
          }
        }
      }
    }
  }

  .noticeAccordionWrapper {
    border-top: 1px solid #707070;

    .headerTitleWrapper {
      display: flex;
      align-items: center;

      .headerTitleNumber {
        width: 60px;
        font-size: 14px;
        color: #707070;
        letter-spacing: 0;
      }

      .headerTitleCategory {
        width: 94px;
        font-size: 14px;
        color: #707070;
        line-height: 23px;
        letter-spacing: 0;
      }

      .headerTitle {
        font-size: 16px;
        line-height: 22px;
        color: #2f2f2f;
        letter-spacing: 0;
      }

      .headerTitleSideWrapper {
        display: flex;
        margin-left: auto;

        .headerTitleCreatedAt {
          font-size: 14px;
          line-height: 19px;
          letter-spacing: 0;
          color: rgba($color: #707070, $alpha: 0.5);
        }
      }
    }
  }
}

.contactAuthSection {
  padding-top: 100px;
  padding-bottom: 135px;
  background-color: #ffffff;


  .contactAuthTitle {
    font-size: 30px;
    font-family: 'Nanum Myeongjo', serif;
    text-align: center;
    padding: 0;
    margin-bottom: 40px;
    letter-spacing: -0.6px;
    color: #2f2f2f;
    font-weight: 400;
  }

  .contactAuthDescription {
    font-family: 'Montserrat', 'Noto Sans KR', sans-serif;
    color: #2f2f2f;
    letter-spacing: 0;
    font-weight: 400;
    text-align: center;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 68px;
  }

  .contactAuthDescriptionBold {
    font-family: 'Montserrat', 'Noto Sans KR', sans-serif;
    color: #2f2f2f;
    letter-spacing: 0;
    font-weight: bold;
    font-size: 18px;
    line-height: 1.5;
    margin-bottom: 68px;
    text-align: center;
  }

  .contactAuthTitleBox {
    background-color: #f6f6f6;
    border-top: 2px solid #e8390e;
    width: 600px;
    margin: 0 auto 40px;
    padding: 16px 0;
    text-align: center;
    font-family: 'Montserrat', 'Noto Sans KR', sans-serif;
    color: #2f2f2f;
    letter-spacing: 0;
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
  }

  .contactAuthBoxDescription {
    font-family: 'Montserrat', 'Noto Sans KR', sans-serif;
    letter-spacing: 0;
    color: #2f2f2f;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    margin-bottom: 34px;
  }

  .contactAuthButtonWrapper {
    padding-bottom: 40px;
    text-align: center;
    border-bottom: 1px solid #dddddd;
    width: 600px;
    margin: 0 auto;

    .contactAuthWhiteButton {
      transition: all 300ms;
      background-color: #ffffff;
      border: 2px solid #dddddd;
      border-radius: 6px;
      padding: 18px 0;
      width: 320px;
      cursor: pointer;
      font-weight: bold;
      font-size: 18px;
      line-height: 24px;
      color: #333333;


      &:hover {
        border: 2px solid #e8390e;
        background: #FFE9E3 0 0 no-repeat padding-box;
        color: #e8390e;
      }

      &:focus {
        outline: none;
      }
    }
  }

}

.checkbox {
  display: block;
  margin: auto 0;
  margin-right: 1.0rem;
}

.label {
  display: block;
  margin: auto 0;
  margin-right: 1.0rem;
}

.label:last-child {
  margin-right: 0;
}

.contactInquirySection {
  background-color: #ffffff;
  padding-bottom: 50px;

  .contactInquirySectionTitle {
    font-size: 24px;
    font-family: "Montserrat", sans-serif;
    line-height: 29px;
    color: #2f2f2f;
    letter-spacing: 0;
    font-weight: bold;
  }

  .contactInquiryDescription {
    margin: 0 auto;
    text-align: center;
  }

  .contactMoveContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 30px;

    .contactButton {
      width: 300px;
      height: 43px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: white;
      border: 1px solid;
      border-radius: 5px;
      color: black;
      cursor: pointer;
    }
  }
}
