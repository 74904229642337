.overlayWrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 400;
}

.contentWrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  background-color: #FFFFFF;
  padding: 0;
  -webkit-overflow-scrolling: touch;
  outline: none;
  width: 640px;
  height: 650px;
  box-shadow: rgba(0, 0, 0, 0.16);
  box-sizing: border-box;
  border-radius: 20px;
  display: flex;
  border: none;

  img {
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
  }
}

.menuOverlayWrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(#000000, 0.5);
  z-index: 400;
}

.menuContentWrapper {
  position: absolute;
  top: 50%;
  left: 70%;
  right: auto;
  bottom: auto;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  background-color: #FFFFFF;
  -webkit-overflow-scrolling: touch;
  outline: none;
  padding: 40px;
  width: 800px;
  height: 560px;
  border-radius: 0;
  box-shadow: rgba(#000000, 0.16);

  img {
    max-height: 300px;
  }
}

.financialStatementsOverlayWrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0);
  z-index: 400;
}

.financialStatementsContentWrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  background-color: #FFFFFF;
  -webkit-overflow-scrolling: touch;
  outline: none;
  box-shadow: rgba(#000000, 0.16);
  border-radius: 16px 16px 0px 0px;
  width: 400px;

  .financialStatePopupHeader {
    border-radius: 16px 16px 0px 0px;
    background: #E8390E;
    height: 75px;
    display: flex;
    justify-content: center;
    align-items: center;

    color: #FFF;
    font-family: "Nanum Myeongjo", serif;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    align-items: center;
  }

  .financialStatePopupContent {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 40px;

    color: #000;
    text-align: center;
    font-family: "Nanum Myeongjo", serif;
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: 45px; /* 150% */
  }

  .financialStatePopupFooterForPc {
    position: relative;
    height: 50px;
    padding-top: 20px;

    .financialStatePopupCloseBtn {
      position: absolute;
      right: 10px;
      background: #333;
      display: flex;
      justify-content: center;
      align-items: center;

      width: 65px;
      height: 40px;
      flex-shrink: 0;

      color: #FFF;
      font-family: "Nanum Myeongjo", serif;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 35px; /* 175% */
    }
  }

  .financialStatePopupFooterForMobile{
    height: 68px;
    display: flex;
    margin-top: 20px;
    justify-content: center;
    align-items: center;
    background: #D9D9D9;
  }
}

.closeButtonWrapper {
  padding-bottom: 33px;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  &.brandModalWrapper {
    position: absolute;
    top: 30px;
    right: 30px;
    padding-bottom: 0;
  }

  .close {
    background-color: transparent;
    border: none;
    font-size: 40px;
    cursor: pointer;
    padding: 0;
    margin: 0;
    line-height: 0.55;
    color: #2f2f2f;

    &.white {
      color: #ffffff;
      text-shadow: 1px 1px 2px #2f2f2f;
    }

    &:focus {
      outline: none;
    }
  }
}

.titleLayerWrapper {
  display: flex;
  padding-bottom: 24px;

  .titleWrapper {
    .normalTitle {
      font-family: "Nanum Myeongjo", serif;
      font-size: 24px;
      letter-spacing: 0;
      color: #2f2f2f;
      line-height: 28px;
      font-weight: bold;
    }

    .enTitle {
      font-family: "Nanum Myeongjo", serif;
      font-size: 16px;
      letter-spacing: 0;
      color: rgba($color: #2f2f2f, $alpha: 0.5);
      line-height: 21px;
      font-weight: bold;
    }
  }

  .price {
    margin-left: auto;
    font-size: 24px;
    font-family: "Nanum Myeongjo", serif;
    letter-spacing: 0;
    color: #2f2f2f;
  }
}

.description {
  font-family: "Nanum Myeongjo", serif;
  font-size: 15px;
  line-height: 16px;
  color: #707070;
  letter-spacing: 0;
  padding-bottom: 32px;
}

.brandDescription {
  font-family: "Nanum Myeongjo", serif;
  font-size: 15px;
  line-height: 19px;
  color: #2f2f2f;
  letter-spacing: -0.3px;
  padding-top: 65px;
  text-align: center;
}

.imageBackgroundWrapper {
  background-color: #f4f4f4;
  padding: 30px;
  text-align: center;
}

@media only screen and (max-width: 1200px) {
  .contentWrapper {
    width: calc(100% - 40px);
    height: 70%;
    overflow-y: auto;
  }

  .brandDescription {
    padding-top: 30px;
    font-size: 12px;
    line-height: 19px;
    letter-spacing: -0.24px;
    word-break: keep-all;
  }

  .menuContentWrapper {
    box-sizing: border-box;
    width: calc(100% - 40px);
    height: 60%;
    overflow-y: auto;
    left: 50%;

    .titleLayerWrapper {
      padding-bottom: 10px;

      .titleWrapper {
        .normalTitle {
          font-family: "Nanum Myeongjo", serif;
          font-size: 18px;
          letter-spacing: 0;
          color: #2f2f2f;
          font-weight: bold;
        }

        .enTitle {
          font-family: "Nanum Myeongjo", serif;
          font-size: 16px;
          letter-spacing: 0;
          color: rgba($color: #2f2f2f, $alpha: 0.5);
          line-height: 21px;
          font-weight: bold;
        }
      }

      .price {
        margin-left: auto;
        font-size: 18px;
        font-family: "Nanum Myeongjo", serif;
        letter-spacing: 0;
        color: #2f2f2f;
      }
    }

    .description {
      font-family: "Nanum Myeongjo", serif;
      font-size: 12px;
      line-height: 16px;
      color: #707070;
      letter-spacing: 0;
      padding-bottom: 30px;
      word-break: keep-all;
    }
  }
}