.storySection {
  padding-top: 20px;
  padding-bottom: 70px;
  position: relative;

  .storyImageWrapper {
    position: relative;
    height: 240px;
    padding-bottom: 40px;

    > img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .storyDescriptionWrapper {
    position: relative;
    padding-left: 20px;
    padding-right: 20px;

    .storyDescriptionSubTitle {
      font-size: 16px;
      font-family: 'Nanum Myeongjo', serif;
      letter-spacing: 0;
      margin-bottom: 8px;
      color: #707070;
      font-weight: 400;
    }

    .storyDescriptionTitle {
      font-size: 24px;
      font-family: 'Nanum Myeongjo', serif;
      color: #517e71;
      font-weight: 400;
      letter-spacing: 0;
      margin-bottom: 10px;
      word-break: keep-all;
    }

    .storyDescriptionDivider {
      height: 90px;
      width: 2px;
      margin-left: 4px;
      background-color: #517E71;
    }

    .storyDescription {
      padding-top: 10px;
      font-size: 16px;
      font-family: 'Nanum Myeongjo', serif;
      letter-spacing: 0;
      color: #707070;
      line-height: 24px;
    }
  }
}

.storeSection {
  position: relative;

  .storePanelWrapper {
    position: relative;

    .storePanelImageWrapper {
      height: 128px;

      > img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .storePanelDescriptionWrapper {
      position: relative;
      padding: 26px 30px;

      .storePanelTitle {
        font-family: 'Nanum Myeongjo', serif;
        font-size: 16px;
        color: #333333;
        letter-spacing: 0;
        margin-bottom: 15px;
        font-weight: bold;
      }

      .storePanelDescription {
        font-size: 12px;
        line-height: 22px;
        color: #333333;
        letter-spacing: 0;
        font-weight: 400;
        font-family: 'Nanum Myeongjo', serif;
      }
    }
  }

  .mapWrapper {
    padding-top: 60px;
    position: relative;

    .mapTitle {
      margin: 20px 0;
      font-size: 16px;
      font-family: 'Nanum Myeongjo', serif;
      font-weight: bold;
      color: #2F2F2F;
      text-align: center;
      letter-spacing: 0;
    }

    .mapDescription {
      color: #2F2F2F;
      font-size: 12px;
      line-height: 17px;
      letter-spacing: 0;
      font-family: 'Nanum Myeongjo', serif;
      text-align: center;
      margin-bottom: 26px;
    }

    .mapStoreLinkWrapper {
      padding: 0 70px;
      margin-bottom: 40px;

      .mapStoreLink {
        display: inline-block;
        width: 100%;
        padding: 21px 0;
        text-align: center;
        background-color: rgba(232, 57, 14, 0.9);
        color: rgba(255, 255, 255, 0.8);
        font-family: 'Nanum Myeongjo', serif;
        font-size: 14px;
        letter-spacing: 0;
        font-weight: 400;
        border-radius: 8px;
        box-shadow: 0 3px 0 rgba(#000, 0.32);
      }
    }
  }

  .storeMapImageWrapper {
    position: relative;

    .storeMapMarkerPosition {
      position: absolute;
      display: inline-block;
    }
  }
}

.newMenuSection {
  position: relative;
  padding: 40px 20px 50px;

  .newMenuTitle {
    font-family: 'Nanum Myeongjo', serif;
    color: #2F2F2F;
    font-size: 24px;
    letter-spacing: 0;
    font-weight: 500;
    margin-bottom: 26px;
    font-style: italic;
  }
}

.specialSection {
  position: relative;
  background-image: url("../../../../static/images/mobile/img-hibarin-special-back@2x.png");
  background-size: cover;
  padding: 46px 20px 51px;

  .specialTitle {
    font-family: 'Nanum Myeongjo', serif;
    color: #2F2F2F;
    font-size: 24px;
    letter-spacing: 0;
    font-weight: 500;
    margin-bottom: 26px;
    font-style: italic;
  }

  .specialMenuWrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    background-color: #FFFFFF;

    > div, > a {
      flex: 0 0 33.333333%;
      width: 100%;
      max-width: 33.333333%;

      img {
        transition: opacity 250ms;
        opacity: 0.34;
      }

      &:hover, &:focus {
        img {
          opacity: 1;
        }
      }
    }
  }
}

.menuNavigatorWrapper {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin: 0;
  padding: 20px 0 0;

  .menuNavigator {
    flex: 0 0 calc(33.333333% - 2px);
    width: 100%;
    max-width: calc(33.333333% - 2px);
    text-align: center;
    border: 1px solid #EEEEEE;
    padding: 12px 0;

    &.menuNavigatorActive {
      border-color: #557B70;

      > a {
        color: #557B70;
      }
    }

    > a {
      color: #707070;
      font-size: 12px;
      line-height: 17px;
      letter-spacing: 0;
      font-family: 'Noto Sans KR', sans-serif;
      display: inline-block;
      white-space: nowrap;
    }
  }
}

.menuTableSection {
  position: relative;

  .bannerImageWrapper {
    height: 270px;
    margin-bottom: 60px;
  }

  .menuTableWrapper {
    position: relative;
    padding: 0 70px;
    margin-bottom: 50px;

    .menuTable {
      position: relative;

      .menuNumber {
        font-family: 'Montserrat', sans-serif;
        font-size: 12px;
        font-weight: 300;
        letter-spacing: 0;
        color: #707070;
        text-align: center;
        margin-bottom: 12px;
      }

      .menuImageWrapper {
        position: relative;
        margin-bottom: 12px;
        text-align: center;
      }

      .menuEnTitle {
        font-family: 'Nanum Myeongjo', serif;
        color: #707070;
        font-size: 12px;
        letter-spacing: 0;
        font-weight: 300;
        font-style: italic;
        margin-bottom: 12px;
        text-align: left;
        white-space: nowrap;
      }

      .menuTitle {
        font-size: 12px;
        font-family: 'Nanum Myeongjo', serif;
        letter-spacing: 0;
        color: #2F2F2F;
        font-weight: 400;
        margin-bottom: 10px;
        text-align: left;
      }

      .menuDescription {
        font-size: 10px;
        font-family: 'Montserrat', 'Noto Sans KR', sans-serif;
        font-weight: 300;
        line-height: 12px;
        color: #707070;
        margin-bottom: 8px;
        text-align: left;
        word-break: keep-all;
      }

      .menuPrice {
        font-size: 16px;
        line-height: 21px;
        font-family: 'Nanum Myeongjo', serif;
        color: #2F2F2F;
        letter-spacing: 0;
        text-align: left;
        margin-bottom: 50px;
      }
    }
  }
}

.sideMenuSection {
  margin: 0 20px 70px;
  padding: 26px 20px;
  position: relative;
  border-top: 1px solid #2F2F2F;
  border-bottom: 1px solid #2F2F2F;

  .sideMenuFlexWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &:not(:last-child) {
      margin-bottom: 26px;
    }

    .sideMenuEnTitle {
      font-size: 12px;
      font-family: 'Nanum Myeongjo', serif;
      color: #707070;
      letter-spacing: 0;
      margin-bottom: 6px;
      font-style: italic;
    }

    .sideMenuTitle {
      font-family: 'Nanum Myeongjo', serif;
      font-size: 12px;
      color: #2F2F2F;
      letter-spacing: 0;
    }

    .sideMenuPrice {
      font-size: 16px;
      color: #2F2F2F;
      letter-spacing: 0;
      font-family: 'Nanum Myeongjo', serif;
      font-style: italic;
    }
  }
}

.drinkMenuSection {
  margin: 0 20px 70px;
  padding: 26px 20px;
  position: relative;
  border-top: 1px solid #2F2F2F;
  border-bottom: 1px solid #2F2F2F;

  .drinkMenuFlexWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &:not(:last-child) {
      margin-bottom: 26px;
    }

    .drinkMenuEnTitle {
      font-size: 14px;
      font-family: 'Montserrat', sans-serif;
      color: #000;
      letter-spacing: 0;
      margin-bottom: 6px;
      font-weight: 500;
    }

    .drinkMenuTitle {
      font-family: 'Noto Sans KR', sans-serif;
      font-size: 12px;
      color: #000;
      letter-spacing: 0;
    }

    .drinkMenuPrice {
      font-size: 16px;
      color: #000;
      letter-spacing: 0;
      font-family: 'Montserrat', sans-serif;
      font-weight: bold;
    }
  }
}