.fabWrapper {
    position: fixed;
    bottom: 30px;
    right: 30px;
    z-index: 400;

    .mapPlaceholderIcon {
        box-sizing: border-box;
        width: 57px;
        height: 57px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 100%;
        background-color: rgba($color: #ea4c25, $alpha: 0.81);

        img {
            width: 27.5px;
            height: 27.5px;
        }
    }

    .instagramIcon {
        box-sizing: border-box;
        width: 57px;
        height: 57px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 100%;
        //border: 1px solid #ffffff;
        background: transparent
            linear-gradient(
                55deg,
                rgba($color: #feb72b, $alpha: 0.73) 0,
                rgba($color: #f54b33, $alpha: 0.73) 52%,
                rgba($color: #a129a9, $alpha: 0.73) 100%
            )
            0 0 no-repeat padding-box;
    }

    .facebookIcon {
        box-sizing: border-box;
        width: 57px;
        height: 57px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 100%;
        background: rgba($color: #3d6ad6, $alpha: 0.81) 0 0 no-repeat
            padding-box;
    }

    .supportIcon {
        box-sizing: border-box;
        width: 57px;
        height: 57px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 100%;
        background: rgba($color: #000000, $alpha: 0.81) 0 0 no-repeat
            padding-box;
    }

    > a,
    > div {
        &:not(:last-child) {
            margin-bottom: 10px;
        }
    }
}
