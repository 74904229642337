a {
  text-decoration: none !important;
}

//�꽌�룷�듃 由ъ뒪�듃 �럹�씠吏�
.mobileSupportSection {
  position: relative;
  padding-top: 60px;
  width: 100%;

  .mobileSupportWrapper {
    padding: 40px 0 15px 0;
    background-color: rgba($color: #000000, $alpha: 0.75);
    z-index: 2;
    position: relative;

    .mobileSupportTitle {
      margin-bottom: 48px;

      color: #ffffff;
      font-size: 24px;
      font-family: "Nanum Myeongjo", serif;
      letter-spacing: 0;
      text-shadow: 0 3px 6px rgba(0, 0, 0, 0.32);
      font-weight: 500;
      text-align: center;
    }

    .mobileSupportMenus {
      padding: 0 24px 0 29px;

      .mobileSupportMenu {
        display: flex;
        flex-direction: row;
        padding: 15px 20px;
        border: 1px solid #ffffff;
        border-radius: 8px;
        margin-bottom: 20px;

        .mobileSupportMenuIcon {
          display: flex;
          margin-right: 47px;
          width: 40px;
          height: 40px;
          background-color: rgba(255, 255, 255, 0.3);
          border-radius: 50%;

          .mobileSupportIconImg {
            margin: auto;
            opacity: 1;
          }
        }

        .mobileSupportMenuText {
          margin: auto 0;
          font-size: 16px;
          letter-spacing: 0px;
          color: #ffffff;
          text-shadow: 0px 3px 6px #00000029;
          opacity: 1;
          text-align: left;
        }
      }
    }

    .mobileSupportMenu:hover {
      background-color: #ffffff;
    }
    .mobileSupportMenu:hover > .mobileSupportMenuText {
      color: #2f2f2f;
    }
    .mobileSupportMenu:hover > .mobileSupportMenuIcon {
      background-color: #e8390e;
    }
  }

  .mobileSupportBgImgWrapper {
    position: absolute;
    width: 100%;
    height: 590px;
    top: 60px;
  }
}

//�꽌�룷�듃 以�鍮꾩쨷 �럹�씠吏�
.supportWarningSection {
  position: relative;
  padding: 31px 26px 39px 23px;
  height: 433px;
  background-color: #f4f4f4;

  .supportWarningWrapper {
    width: 100%;
    background-color: #ffffff;

    .supportWarning {
      padding: 40px 38px 33px 38px;

      .supportWarningTitle {
        margin-bottom: 29.93px;
        font-size: 20px;
        line-height: 34px;
        letter-spacing: -0.4px;
        font-family: "Nanum Myeongjo", serif;
        font-weight: bold;
        text-align: center;
      }

      .supportWarningImage {
        display: block;
        margin: 0 auto 28.97px auto;
        width: 165.98px;
        height: 169.09px;
      }

      .supportWarningText {
        font-size: 12px;
        line-height: 18px;
        letter-spacing: -0.24px;
        text-align: center;
        color: #2f2f2f;
      }

      .supportWarningTextBold {
        font-weight: bold;
      }
    }
  }
}

//�꽌�룷�듃 移댁뭅�삤 移쒓뎄 �럹�씠吏�
.supportKakaoSection {
  padding: 31px 26px 28px 23px;

  .supportKakaoWrapper {
    padding: 32.95px 20px 15px 20px;
    background-color: #ffe833;
  }

  .supportKakaoIcon {
    display: block;
    margin: 0 auto 4.5px auto;
    width: 34px;
    height: 32px;
  }

  .supportKakaoTitle {
    font-size: 16px;
    line-height: 21px;
    letter-spacing: 0.32px;
    color: #2f2f2f;
    text-align: center;
  }
  .supportKakaoTitleBold {
    font-weight: bold;
  }

  .supportKakaoText {
    margin-bottom: 19px;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: -0.24px;
    text-align: center;
  }

  .supportKakaoImage {
    position: relative;
    left: calc(50% - 108.025px - 12.025px);
    margin-bottom: 19px;
    width: 216.05px;
    height: 188px;
  }

  .supportKakaoSeqs {
    margin-bottom: 19px;
    padding: 0 14px;

    .supportKakaoSeq {
      display: flex;
      flex-direction: row;
      margin-bottom: 12px;
      align-items: center;

      .supportKakaoSeqNum {
        display: flex;
        margin-right: 14px;
        width: 20px;
        min-width: 20px;
        height: 20px;
        background-color: #391b1b;
        border-radius: 50%;

        .supportKakaoSeqNumText {
          margin: auto;
          font-size: 12px;
          line-height: 10px;
          letter-spacing: 0.24px;
          color: #ffffff;
        }
      }

      .supportKakaoSeqText {
        margin: auto 0;
        font-size: 12px;
        letter-spacing: -0.24px;
        text-align: left;
        white-space: nowrap;
        line-height: 16px;
      }
    }
  }

  .supportKakaoMenus {
    .supportKakaoMenuLink {
      display: inline-block;
      width: 100%;

      .supportKakaoMenu {
        display: flex;
        flex-direction: row;
        margin-bottom: 10px;
        padding: 15px 22.17px 14.45px 22.17px;
        background-color: #ffffff;
        border: 2px solid #dbbb19;
        border-radius: 8px;

        .supportKakaoMenuLogoSaboten {
          margin: auto 50px auto 0;
        }
        .supportKakaoMenuLogoHibarin {
          margin: auto 55px auto 0;
        }
        .supportKakaoMenuLogoCentral {
          margin: auto 15px auto 0;
        }

        .supportKakaoMenuText {
          font-size: 12px;
          line-height: 18px;
          letter-spacing: -0.24px;
          text-align: left;
          color: #2f2f2f;
          white-space: nowrap;
        }
        .supportKakaoMenuTextBold {
          font-weight: bold;
        }
      }
    }
  }
}

//�꽌�룷�듃 �긽�뭹沅� �럹�씠吏�
.supportGiveawaySection {
  position: relative;
  padding: 31px 23px 32px 23px;

  .supportGiveawayWrapper {
    padding: 40px 0 29px 0;
    background-color: #2f2f2f;

    .supportGiveawayTitle {
      margin-bottom: 42.35px;
      font-size: 20px;
      line-height: 34px;
      letter-spacing: -0.4px;
      text-align: center;
      color: #ffffff;
      font-family: "Nanum Myeongjo", serif;
    }

    .supportGiveawaylines {
      display: flex;
      justify-content: space-between;
      margin-bottom: 51.72px;

      .supportGiveawayline {
        margin: auto 0 auto 0;
        width: 100%;
        border-bottom: 1px solid #ffffff;
        opacity: 0.5;
      }

      .supportGiveawayLogo {
        display: block;
        margin: 0 12.5px;
        width: 83.85px;
        height: 18.68px;
      }
    }

    .supportGiveawayImg {
      display: block;
      margin: 0 auto 15px auto;
      width: 277px;
    }

    .supportGiveawayDescs {
      padding: 0 17px;

      .supportGiveawayDesc {
        display: flex;
        margin-bottom: 9px;

        .supportGiveawayDescIndex {
          margin-top: 5px;
          margin-right: 20px;
          width: 5px;
          min-width: 5px;
          height: 5px;
          border-radius: 50%;
          background-color: #ffffff;
        }

        .supportGiveawayDescText {
          font-size: 12px;
          line-height: 18px;
          letter-spacing: -0.24px;
          text-align: left;
          color: #ffffff;
        }
      }

      .supportGiveawayDescDetails {
        padding: 16px 0 23px 26px;

        .supportGiveawayDescDetail {
          display: flex;
          margin-bottom: 5px;

          .supportGiveawayDescDetailIndex {
            margin-top: 5px;
            margin-right: 20px;
            width: 4px;
            min-width: 4px;
            height: 4px;
            border-radius: 50%;
            border: 1px solid #ffffff;
          }

          .supportGiveawayDescText {
            font-size: 12px;
            line-height: 18px;
            letter-spacing: -0.24px;
            text-align: left;
            color: #ffffff;
          }
        }
      }
    }
  }
}

//�꽌�룷�듃 �씠踰ㅽ듃 �럹�씠吏� - 硫붾돱
.supportEventMenuSection {
  margin: 20px 0;

  .supportEventMenus {
    display: flex;
    flex-direction: row;
    justify-content: center;

    a {
      width: 100%;
    }

    .supportEventMenu {
      display: flex;
      width: 100%;
      height: 39px;
      border: 1px solid #eeeeee;

      .supportEventMenuText {
        margin: auto;
        font-size: 12px;
        text-align: center;
        line-height: 17px;
        color: #707070;
      }
    }

    .supportEventMenuActive {
      display: flex;
      width: 100%;
      height: 39px;
      border: 1px solid #e8390e;

      .supportEventMenuText {
        margin: auto;
        font-size: 12px;
        text-align: center;
        line-height: 17px;
        color: #e8390e;
      }
    }
  }
}

//�꽌�룷�듃 �씠踰ㅽ듃 �럹�씠吏� - 寃뚯떆臾�
.supportEventSection {
  display: flex;
  justify-content: center;
  padding: 0 16px 26.5px 16px;

  .supportEventWrapper {
    .supportEventPanels {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;

      > a {
        display: flex;
        width: 100%;
        margin: 0 10.25px 16.5px 10.25px;
        flex: 0 0 calc(50% - 21px);
        max-width: calc(50% - 21px);
      }

      .supportEventPanel {
        border-radius: 10px;
        box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
        height: 100%;

        .supportEventPanelImage {
          display: block;
          width: 100%;
          height: 162.5px;
          object-fit: cover;
          border-top-left-radius: 10px;
          border-top-right-radius: 10px;
        }

        .supportEventPanelDesc {
          padding: 16.5px 14px 22.5px 14px;

          .supportEventPanelTitle {
            margin-bottom: 6px;
            font-size: 12px;
            line-height: 14px;
            color: #2f2f2f;
            font-family: "Nanum Myeongjo", serif;
            font-weight: bold;
          }
          .supportEventPanelDate {
            font-size: 10px;
            line-height: 11px;
            color: #707070;
            font-family: "Nanum Myeongjo", serif;
            font-weight: 400;
          }
        }
      }
    }
  }
}

//�꽌�룷�듃 �씠踰ㅽ듃 �럹�씠吏� - �옄�꽭�엳
.supportEventDetailSection {
  padding: 10px 15px 26.5px 15px;

  .supportEventDetailWrapper {
    margin: 0 auto;

    .supportEventDetailTitle {
      margin-bottom: 5px;
      font-size: 14px;
      line-height: 17px;
      color: #2f2f2f;
      font-family: "Nanum Myeongjo", serif;
      font-weight: bold;
    }

    .supportEventDetailDate {
      font-size: 10px;
      line-height: 11px;
      color: #707070;
      font-family: "Nanum Myeongjo", serif;
      font-weight: 400;
    }

    .supportEventDetailHorizen {
      margin: 11px 0 20.5px 0;
      border-bottom: 1px solid #eeeeee;
    }

    .supportEventDetailImage {
      display: block;
      margin-bottom: 20px;
      width: 100%;
      height: auto;
      object-fit: cover;
    }

    .supportEventDetailDesc {
      margin-bottom: 25.5px;
      font-size: 12px;
      line-height: 24px;
    }
  }
}

 .giveawayPanelMembership {
	
	 padding: 31px 23px 32px 23px;
}
