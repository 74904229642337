@import url("//fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
//@import url(//fonts.googleapis.com/earlyaccess/nanummyeongjo.css);
//@import url(//fonts.googleapis.com/earlyaccess/notosanskr.css);

@import "./fonts";

html,
body {
  position: relative;
  margin: 0;
  background-color: #ffffff;
  font-family: "Noto Sans KR", sans-serif;
}

.ReactModalPortal {
  position: relative;
}

.menu-modal-open {
  overflow: hidden;
}

img {
  vertical-align: middle;
}

#root {
  position: relative;
}

#modal-root {
  position: relative;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-no-decoration {
  text-decoration: none;
}

.align-self-center {
  align-self: center;
}

.align-items-center {
  align-items: center;
}

.d-block {
  display: block;
}

.d-inline-block {
  display: inline-block;
}

.d-flex {
  display: flex;
}

.d-inline-flex {
  display: inline-flex;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.my-auto {
  margin-top: auto;
  margin-bottom: auto;
}

.w-100 {
  width: 100%;
}

.h-100 {
  height: 100%;
}

.wh-100 {
  width: 100%;
  height: 100%;
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-fill {
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 1;
}

.position-relative {
  position: relative;
}

.row {
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
  width: 100%;
  position: relative;
  min-height: 1px;
}

.container {
  width: 100%;
  max-width: 1140px;
  margin-left: auto;
  margin-right: auto;

  @media screen and (max-width: 576px) {
    max-width: 100%;
  }

  @media screen and (min-width: 576px) {
    max-width: 540px;
  }

  @media screen and (min-width: 768px) {
    max-width: 720px;
  }

  @media screen and (min-width: 992px) {
    max-width: 960px;
  }

  @media screen and (min-width: 1140px) {
    max-width: 1140px;
  }
}

.footer {
  background-color: #2f2f2f;
  padding: 18px 32px;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  font-size: 12px;
  line-height: 20px;
  color: #ffffff;
  font-family: "Montserrat", sans-serif;
  word-break: keep-all;
  box-sizing: border-box;
  //min-width: 1800px;

  .sitemap-text {
    letter-spacing: -0.32px;
    color: #e8390e;
    text-decoration: none;
  }

  .footer-logo-wrapper {
    padding-right: 3%;
    //padding-bottom: 5%;
  }
}

.justify-content-center {
  justify-content: center;
}

.kalisco-text {
  letter-spacing: -0.32px;
  color: #e8390e;
  text-decoration: none;
}
