.bannerWrapper {
  position: relative;
  width: 100%;
  height: 100%;

  .bannerImageWrapper {
    height: 200px;
    position: relative;

    > img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .bannerLogoWrapper {
      position: absolute;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      box-sizing: border-box;
      padding-top: 60px;

      &.sabotenBannerLogoWrapper {
        background-color: rgba(0, 0, 0, 0.39);
      }
    }
  }

  .bannerNavigationWrapper {
    background-color: #FFFFFF;
    position: relative;
    border-bottom: 1px solid #EEEEEE;
    padding-top: 22px;
    box-sizing: border-box;
    padding-left: 13px;
    padding-right: 13px;

    .bannerNavigationFlexWrapper {
      list-style: none;
      margin: 0;
      padding: 0;
      display: flex;
      overflow-x: auto;
      overflow-y: hidden;

      .bannerNavigation {
        flex-basis: 0;
        flex-grow: 0;
        white-space: nowrap;

        &:not(:last-child) {
          margin-right: 23px;
        }

        .bannerNavigationLink {
          color: rgba(#333, 0.5);
          padding-bottom: 8px;
          display: inline-block;
          padding-left: 18px;
          padding-right: 18px;
          font-size: 12px;
          font-family: 'Montserrat', 'Noto Sans KR', sans-serif;
          font-weight: 400;
          line-height: 15px;
          letter-spacing: 0;

          &.bannerNavigationLinkActive {
            font-weight: bold;

            &.hibarin {
              color: #557B70;
              border-bottom: 2px solid #557B70;
            }

            &.saboten {
              color: #C70017;
              border-bottom: 2px solid #C70017;
            }
          }
        }
      }
    }
  }
}
