//@import "~bootstrap/scss/bootstrap";

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/buttons";
@import "~bootstrap/scss/transitions";
@import "~bootstrap/scss/dropdown";
@import "~bootstrap/scss/card";
@import "~bootstrap/scss/print";
@import "~bootstrap/scss/tooltip";

section {
  .card {
    background-color: #ffffff;
    border-top: none !important;

    border-left: none !important;
    border-right: none !important;
    border-bottom: 1px solid #dddddd !important;

    .card-header {
      background-color: #ffffff;
      border: none;
      border-bottom: 1px solid #dddddd;
      cursor: pointer;
    }

    .card-body {
      white-space: pre-line;
      line-height: 1.5;
      letter-spacing: -0.54px;
    }
  }
}
