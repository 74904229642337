.categoryButton {
    background-color: transparent;
    border: 1px solid #dddddd;
    border-radius: 30px;
    min-width: 122px;
    padding: 16px 24px;
    letter-spacing: 0px;
    color: #707070;
    text-align: center;
    display: inline-block;
    cursor: pointer;
    transition: all 250ms;
    text-decoration: none;
    box-sizing: border-box;

    &:hover {
        background-color: rgba($color: #ffffff, $alpha: 0.8);
        border: 1px solid rgba($color: #e8390e, $alpha: 0.8);
        color: rgba($color: #e8390e, $alpha: 0.8);

        &.darkOutline {
            background-color: transparent;
        }
    }

    &.active {
        background-color: #ffffff;
        border: 1px solid #e8390e;
        color: #e8390e;
        
        &.darkOutline {
            background-color: transparent;
        }
    }

    &:not(:last-child) {
        margin-right: 10px;
    }

    &:focus {
        outline: none;
    }
}
