.bannerWrapper {
  position: relative;
  padding-top: 60px;

  .bannerImageWrapper {
    position: relative;

    .bannerTitleWrapper {
      position: absolute;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 24px;
      color: #FFFFFF;
      font-family: 'Nanum Myeongjo', serif;
      letter-spacing: 0;
      text-shadow: 0 3px 6px rgba(0,0,0,0.32);

      &.bannerTitleBgDark {
        background-color: rgba(0, 0, 0, 0.5);
      }
    }

    .bannerImage {
      width: 100%;
      height: 110px;
      object-fit: cover;
    }
  }

  .bannerNavigationWrapper {
    position: relative;

    .bannerNavigationFlexWrapper {
      display: flex;
      list-style: none;
      padding: 20px 0 0;
      margin: 0;
      width: 100%;
      overflow-x: auto;

      .bannerNavigation {
        border-bottom: 1px solid #EEEEEE;
        max-width: 100%;
        position: relative;
        padding-left: 15px;
        padding-right: 15px;
        padding-bottom: 8px;
        text-align: center;
        flex-basis: 0;
        flex-grow: 1;

        &.scrollableNavigation {
          flex: 0 0 auto !important;
        }

        .bannerNavigationLink {
          text-decoration: none;
          color: rgba($color: #333333, $alpha: 0.5);
          font-size: 12px;
          font-weight: 300;
          line-height: 14px;
          letter-spacing: 0;
          font-family: 'Montserrat', 'Noto Sans KR', sans-serif;
        }

        &.active {
          border-bottom: 2px solid #e8390e;

          .bannerNavigationLink {
            color: #E8390E;
            font-weight: bold;
          }
        }
      }
    }
  }
}