.aboutTextWrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .aboutTextTitle {
    font-size: 32px;
    color: #2f2f2f;
    text-align: center;
    letter-spacing: 0;
    font-family: "Nanum Myeongjo", serif;
    margin: 0;

    .emphasis {
      display: inline-block;
      color: #e8390e;
      font-weight: 700;
    }
  }

  .aboutTextParagraph {
    font-size: 20px;
    color: #2f2f2f;
    text-align: center;
    letter-spacing: 0;
    font-family: "Nanum Myeongjo", serif;
    margin: 0;

    .emphasis {
      display: inline-block;
      color: #e8390e;
      font-weight: 700;
    }
  }
}

.gridWrapper {
  width: 100%;
  height: 475px;
  display: flex;
  flex-wrap: wrap;

  .gridColumn {
    max-width: 33.3%;
    flex-basis: 50%;
    position: relative;

    &:first-child {
      max-width: 33.4%;
    }

    &:hover {
      .gridBackgroundWrapper {
        background-color: rgba($color: #000000, $alpha: 0.3);
      }
    }

    .gridBackgroundWrapper {
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: rgba($color: #000000, $alpha: 0.5);
      transition: background-color 500ms;
      z-index: 100;

      .gridText {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: "Nanum Myeongjo", serif;
        letter-spacing: 0px;
        color: #ffffff;
        text-shadow: 0px 3px 6px #00000029;
        font-size: 33px;
      }
    }

    .aboutMenuImgWrapper {
      height: 100%;
    }
  }
}

.historyWrapper {
  background-color: #ffffff;
  padding: 190px 0 100px;

  .historyLogoWrapper {
    text-align: center;
    padding-bottom: 184px;
  }

  .timeline {
    position: relative;
    margin: 0 auto;
    width: 1680px;
  }

  .timeline ul li {
    margin-bottom: 50px;
    list-style-type: none;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
  }

  .point {
    min-width: 20px;
    height: 20px;
    background-color: #ffffff;
    border-radius: 100%;
    z-index: 2;
    transition: all 1s;
    border: 7px solid #dddddd;
    position: relative;
    left: 1px;
    align-self: flex-start;
    margin-left: 20px;
    margin-right: 20px;
  }

  .timeline ul li .content {
    width: 50%;
  }

  .timeline ul li .date {
    width: 50%;
    font-weight: normal;
  }

  .timeline ul li .date h4 {
    background-color: #e1ccec;
    width: 100px;
    text-align: center;
    padding: 5px 10px;
    border-radius: 10px;
  }

  .timeline ul li .content h3 {
    padding: 10px 20px;
    background-color: #be9fe1;
    margin-bottom: 0;
    text-align: center;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }

  .timeline ul li {
    flex-direction: row;
  }

  .timeline::before {
    content: "";
    position: absolute;
    height: calc(100% - 330px);
    width: 1px;
    left: 50%;
    margin-top: 200px;
    background-color: #dddddd;
  }

  .dateText {
    font-family: "Nanum Myeongjo", serif;
    font-size: 30px;
    letter-spacing: 0;
    color: #2f2f2f;
    //padding: 0 20px;
    font-style: italic;
    line-height: 39px;
    padding-bottom: 24px;
  }

  .contentTitle {
    font: normal normal bold 18px/20px "Nanum Myeongjo";
    font-weight: bold;
    letter-spacing: 0;
    color: #2f2f2f;
    padding-bottom: 12px;
  }

  .contentDate {
    font-family: "Nanum Myeongjo", serif;
    font-size: 18px;
    letter-spacing: 0;
    color: #e8390e;
    padding-bottom: 8px;
  }

  .contentParagraph {
    font-family: "Nanum Myeongjo", serif;
    font-size: 16px;
    letter-spacing: 0px;
    color: #707070;
  }
}

.financialStatementsWrapper {
  background-color: #ffffff;
  padding: 120px 0 550px;

  .financialStatementsTitle {
    display: flex;
    align-items: center;
    justify-content: center;


    font-family: "Nanum Myeongjo", serif;
    font-size: 50px;
    font-weight: 400;
    letter-spacing: 0;
  }

  .financialStatementsAccordionContainer {
    max-width: 1140px;
    margin: 0 auto;

    .financialStatementsAccordionWrapper {
      width: 100%;
      border-top: 1px solid #707070;
      margin-top: 20px;

      .headerTitleWrapper {
        display: flex;
        align-items: center;

        .headerTitleNumber {
          width: 60px;
          font-size: 14px;
          color: #707070;
          letter-spacing: 0;
        }

        .headerTitleCategory {
          width: 94px;
          font-size: 14px;
          color: #707070;
          line-height: 23px;
          letter-spacing: 0;
        }

        .headerTitle {
          font-size: 16px;
          line-height: 22px;
          color: #2f2f2f;
          letter-spacing: 0;
        }

        .headerTitleSideWrapper {
          display: flex;
          margin-left: auto;

          .headerTitleCreatedAt {
            font-size: 14px;
            line-height: 19px;
            letter-spacing: 0;
            color: rgba($color: #707070, $alpha: 0.5);
          }
        }
      }

      .cardBody {
        display: flex;
        justify-content: center;
      }
    }
  }


}
